import { AssignmentManager } from '../web-components/AssignmentManager'

export const AssignmentManagerPage = () => {

    return (
        <div className={'mt-5'}>
            <AssignmentManager />
        </div>
    )
}
