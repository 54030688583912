import { ConfigCreateHTHTerritories, ConfigSendInvitationNewCongregation, ConfigSetCongregationName } from './config-subcomp';
import { H2, Hr } from '../commons';
import { hideLoadingModalReducer, setConfigurationReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from '../commons/custom-hooks';
import { useNavigate } from 'react-router';
import * as services from '../../services';

const btnClasses = 'btn-size12 d-block mx-auto px-3 mt-5 mb-0';

const btnStyles = { width: '350px', minHeight: '80px' }

const rowStyles = { marginTop: '14px', width: '400px' }

export const Config = () => {
    const [showCreateHthTerritories, setShowCreateHthTerritories] = useState(false);
    const [showInvitationForNewCongregation, setShowInvitationForNewCongregation] = useState(false);
    const [showSetCongregationName, setShowSetCongregationName] = useState(false);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const setDisableEditHthMapsHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await services.setDisableEditMapsService(!config.isDisabledEditHthMaps);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: `No se pudo ${config.isDisabledEditHthMaps ? 'habilitar' : 'deshabilitar'} la edición de Mapas`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setDisableCloseHthFacesHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await services.setDisableCloseHthFacesService(!config.isDisabledCloseHthFaces);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: `No se pudo ${config.isDisabledEditHthMaps ? 'habilitar' : 'deshabilitar'} la función de cerrar Caras`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setDisableHthFaceObservatiosHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await services.setDisableHthFaceObservatiosService(!config.isDisabledHthFaceObservations);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: `No se pudo ${config.isDisabledEditHthMaps ? 'habilitar' : 'deshabilitar'} la función de Observaciones de Caras`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setUsingLettesForBlocksHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await services.setUsingLettesForBlocksService(!config.usingLettersForBlocks);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: `No se pudo ${config.usingLettersForBlocks ? 'deshabilitar' : 'habilitar'} el uso de letras para identificar las manzanas`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const downloadBackupHandler = async (withFiles: boolean, all: boolean) => {
        dispatch(showLoadingModalReducer());
        const success = await services.downloadDbBackupService(withFiles, all);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: "No se pudo generar un Backup de la base de datos. Ver los Logs.",
                animation: 2
            }));
        }
        if (!all) {
            dispatch(setConfigurationReducer({ ...config, dbBackupLastDate: services.getCurrentLocalDate() }));
        }
    }

    useEffect(() => services.goToTop(), []);

    return (<>
    
        <H2 title={'CONFIGURACIÓN DE LA APLICACIÓN'} mb={'80px'} />

        {!showCreateHthTerritories && !showInvitationForNewCongregation && !showSetCongregationName && <>

            <button className={`btn btn-general-blue ${btnClasses}`} style={btnStyles} onClick={() => setShowSetCongregationName(true)}>
                {!!config.name ? "Cambiar el nombre de la Congregación" : "Cargar el nombre de la Congregación"}
            </button>

            {!!config.name && !config.numberOfTerritories &&
                <button className={`btn btn-general-blue ${btnClasses}`} style={btnStyles} onClick={() => setShowCreateHthTerritories(true)}>
                    Crear Territorios de Casa en Casa
                </button>
            }

            <button className={`btn btn-general-blue ${btnClasses}`} style={btnStyles}
                onClick={() => downloadBackupHandler(true, false)}
            >
                Generar y descargar una copia de seguridad de la base de datos incluyendo archivos del Tablero (
                    {config.dbBackupLastDate ? `última: ${config.dbBackupLastDate}` : "nunca se hizo una"})
            </button>

            <button className={`btn btn-general-blue ${btnClasses}`} style={btnStyles}
                onClick={() => downloadBackupHandler(false, false)}
            >
                Generar y descargar una copia de seguridad de la base de datos sin incluir archivos del Tablero (
                    {config.dbBackupLastDate ? `última: ${config.dbBackupLastDate}` : "nunca se hizo una"})
            </button>

            {user.isSuperAdmin &&
                <>
                <button className={`btn btn-general-blue ${btnClasses}`} style={btnStyles}
                    onClick={() => downloadBackupHandler(true, true)}
                    >
                    Generar y descargar una copia de seguridad de la base de datos de todas las Congregaciones incluyendo archivos del Tablero
                </button>
                <button className={`btn btn-general-blue ${btnClasses}`} style={btnStyles}
                    onClick={() => downloadBackupHandler(false, true)}
                    >
                    Generar y descargar una copia de seguridad de la base de datos de todas las Congregaciones sin incluir archivos del Tablero
                </button>
                </>
            }

            {/* <h5 className={isDarkMode ? 'text-white' : ''}> Establecer localidad </h5>

            <h5 className={isDarkMode ? 'text-white' : ''}> Duración de accesos: 3 meses </h5> */}

            {config.congregation === 1 && <>
                <button className={`btn btn-general-blue ${btnClasses}`}
                    style={btnStyles}
                    onClick={() => setShowInvitationForNewCongregation(true)}
                >
                    Enviar invitación para Congregación nueva
                </button>

                <button className={`btn btn-general-blue ${btnClasses}`}
                    style={btnStyles}
                    onClick={() => navigate('/gmail')}
                >
                    Renovar credenciales de Gmail
                </button>
            </>}

            <Hr classes={'mx-auto mt-5'} styles={{ maxWidth: isMobile ? '100%' : '70%' }} />

            <H2 title={'CASA EN CASA'} mt={'30px'} />

            <div className={'container mt-5'}>
                <div className={'d-flex justify-content-center'}>
                    <div className={'row '} style={rowStyles}>
                        <div className={'col-2'}>
                            <input type='checkbox'
                                className={'form-check-input checkbox-md'}
                                checked={!config.isDisabledEditHthMaps}
                                onChange={() => setDisableEditHthMapsHandler()}
                            />
                        </div>
                        <div className={'col-10 pt-1'}>
                            <h5 className={isDarkMode ? 'text-white' : ''}>
                                Habilitar edición de Mapas
                            </h5>
                        </div>
                    </div>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <div className={'row'} style={rowStyles}>
                        <div className={'col-2'}>
                            <input type='checkbox'
                                className={'form-check-input checkbox-md'}
                                checked={!config.isDisabledCloseHthFaces}
                                onChange={() => setDisableCloseHthFacesHandler()}
                            />
                        </div>
                        <div className={'col-10 pt-1'}>
                            <h5 className={isDarkMode ? 'text-white' : ''}>
                                Habilitar función de cerrar Caras
                            </h5>
                        </div>
                    </div>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <div className={'row'} style={rowStyles}>
                        <div className={'col-2'}>
                            <input type='checkbox'
                                className={'form-check-input checkbox-md'}
                                checked={!config.isDisabledHthFaceObservations}
                                onChange={() => setDisableHthFaceObservatiosHandler()}
                            />
                        </div>
                        <div className={'col-10'}>
                            <h5 className={isDarkMode ? 'text-white' : ''}>
                                Habilitar función de Observaciones de Caras
                            </h5>
                        </div>
                    </div>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <div className={'row'} style={rowStyles}>
                        <div className={'col-2 pt-1'}>
                            <input type='checkbox'
                                className={'form-check-input checkbox-md'}
                                checked={config.usingLettersForBlocks}
                                onChange={() => setUsingLettesForBlocksHandler()}
                            />
                        </div>
                        <div className={'col-10'}>
                            <h5 className={isDarkMode ? 'text-white' : ''}>
                                Usar letras para identificar las manzanas en vez de números
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>}

        {showCreateHthTerritories &&
            <ConfigCreateHTHTerritories
                setShowCreateHthTerritories={setShowCreateHthTerritories}
            />
        }

        {showSetCongregationName &&
            <ConfigSetCongregationName
                setShowSetCongregationName={setShowSetCongregationName}
            />
        }

        {showInvitationForNewCongregation &&
            <ConfigSendInvitationNewCongregation
                setShowInvitationForNewCongregation={setShowInvitationForNewCongregation}
            />
        }

    </>)
}
