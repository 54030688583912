import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeBoardItem, typeItemBoardFile, typeResponseData } from '../models';

const base: string = pointer.board;

const blindFile = (file: typeItemBoardFile) => ({ ...file, data: null });

const blindItem = (item: typeBoardItem) => ({ ...item, files: item.files.map(f => blindFile(f)) });

const blindItems = (items: typeBoardItem[]) => items.map(item => blindItem(item));

export const addBoardItemService = async (title: string): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ title })
        });
        const data: typeResponseData|null = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteBoardItemFileService = async (item: typeBoardItem, file: typeItemBoardFile): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({
                item: blindItem(item),
                file: blindFile(file)
            })
        });
        const data: typeResponseData|null = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteBoardItemService = async (item: typeBoardItem): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({
                item: blindItem(item)
            })
        });
        const data: typeResponseData|null = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const getBoardItemsService = async (): Promise<typeBoardItem[]|null> => {
    if (!getTokenFromLSService()) return null;
    try {
        const response = await fetch(base, {
            method: 'GET',
            headers: getHeaders()
        });
        const data: typeResponseData|null = await response.json();
        if (!data?.success || !data?.boardItems) return null;
        return data.boardItems;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const reorderBoardItemsService = async (items: typeBoardItem[]): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({
                items: blindItems(items)
            })
        });
        const data: typeResponseData|null = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}
