import React, { useState } from 'react';
import { typeHorario, typePunto, typeDisponibilidad, typeDisponibilidadForm } from '../../../../models/carritos';
import { BsTrash } from 'react-icons/bs';

interface DisponibilidadFormProps {
  puntos: typePunto[];
  dias: string[];
  horarios: typeHorario[];
  disponibilidad: typeDisponibilidad[];
  onAddDisponibilidad: (disponibilidad: typeDisponibilidadForm) => void;
  onRemoveDisponibilidad: (puntoIndex: number, diaIndex: number, horarioIndex: number) => void;
}

const DisponibilidadCarritosForm: React.FC<DisponibilidadFormProps> = ({
  puntos,
  dias,
  horarios,
  disponibilidad,
  onAddDisponibilidad,
  onRemoveDisponibilidad
}) => {
  const [selectedPunto, setSelectedPunto] = useState<string>('');
  const [selectedDia, setSelectedDia] = useState<string>('');
  const [selectedHorario, setSelectedHorario] = useState<string>('');

  const handleAddDisponibilidad = () => {
    if (selectedPunto && selectedDia && selectedHorario) {
      const newDisponibilidad: typeDisponibilidadForm = {
        punto: selectedPunto,
        dia: selectedDia,
        horario: selectedHorario
      };
      onAddDisponibilidad(newDisponibilidad);
      setSelectedPunto(''); // Limpia el estado
      setSelectedDia(''); // Limpia el estado
      setSelectedHorario(''); // Limpia el estado
    }
  };

  const handleDeleteDisponibilidad = (puntoIndex: number, diaIndex: number, horarioIndex: number) => {
    onRemoveDisponibilidad(puntoIndex, diaIndex, horarioIndex);
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="mb-2">
          <h5>Agregar disponibilidad:</h5>

          <label className="form-label">Seleccionar Punto:</label>
          <div className="d-flex flex-wrap gap-1">
            {puntos?.map((punto, index) => (
              <button
                key={index}
                type="button"
                className={`btn btn-outline-general-blue me-2 ${
                  selectedPunto === punto.name ? 'btn-general-blue' : ''
                }`}
                onClick={() => setSelectedPunto(punto.name)}>
                {punto.name}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Seleccionar Día:</label>
          <div className="d-flex flex-wrap gap-1">
            {dias?.map((dia, index) => (
              <button
                key={index}
                type="button"
                className={`btn btn-outline-general-blue me-2 ${selectedDia === dia ? 'btn-general-blue' : ''}`}
                onClick={() => setSelectedDia(dia)}>
                {dia}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Seleccionar Horario:</label>
          <div className="d-flex flex-wrap gap-1">
            {horarios?.map((horario, index) => (
              <button
                key={index}
                type="button"
                className={`btn btn-outline-general-blue me-2 ${
                  selectedHorario === horario.start + '-' + horario.end ? 'btn-general-blue' : ''
                }`}
                onClick={() => setSelectedHorario(horario.start + '-' + horario.end)}>
                {horario.start + '-' + horario.end}
              </button>
            ))}
          </div>
        </div>

        <div className="d-flex flex-column gap-2 w-100 px-4 mt-3">
          <button
            disabled={!selectedPunto || !selectedDia || !selectedHorario}
            type="button"
            className="btn btn-general-blue mb-3 text-center"
            onClick={handleAddDisponibilidad}>
            Agregar Disponibilidad
          </button>
        </div>
      </div>

      <div className="col-md-6">
        <h5>Disponibilidad:</h5>
        <div className="d-flex flex-column gap-2 w-100 px-4" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {disponibilidad.length <= 0 && (
            <div className="text-center card shadow rounded p-4">
              Selecciona punto, dia y horario, para agregar disponibilidad
            </div>
          )}
          {disponibilidad?.map((disp, puntoIndex) => (
            <li className="border p-2 rounded shadow list-group-item" key={puntoIndex}>
              <h5 className="text-center">{disp.punto}</h5>
              <ul>
                {disp.horarios?.map((hor, diaIndex) => (
                  <li key={diaIndex}>
                    {hor.dia}:
                    <article className="d-flex flex-wrap flex-column gap-2 p-2">
                      {hor.horarios?.map((horario, horarioIndex) => (
                        <div
                          className="d-flex justify-content-between align-items-center bg-light border rounded p-2"
                          key={horarioIndex}>
                          {horario}
                          <div>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm m-auto"
                              onClick={() => handleDeleteDisponibilidad(puntoIndex, diaIndex, horarioIndex)}>
                              Eliminar {''}
                              <BsTrash />
                            </button>
                          </div>
                        </div>
                      ))}
                    </article>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </div>
      </div>

    </div>
  );
};

export default DisponibilidadCarritosForm;
