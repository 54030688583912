import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { BoardItemDisplay } from '../board/BoardItemDisplay';
import { BoardManager } from '../board/BoardManager';
import { BoardSideBar } from '../board/BoardSideBar';
import { getBoardItemsService, goToTop } from '../../services';
import { H2, Hr, Loading } from '../commons';
import { pdfjs } from 'react-pdf';
import { typeBoardItem } from '../../models';
import { useEffect, useState } from 'react';
import { useGetCurrentUser, useGetIsMobile } from '../commons/custom-hooks';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export const BoardPage = () => {
    const [counterReload, setCounterReload] = useState(0);
    const [currentItem, setCurrentItem] = useState<typeBoardItem | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<typeBoardItem[] | null>(null);
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    useEffect(() => {
        goToTop();
        getBoardItemsService().then((boardItems0: typeBoardItem[]|null) => {
            setIsLoading(false);
            if (boardItems0) setItems(boardItems0);
        });
    }, [counterReload]);

    if (isLoading) return <Loading mt={'120px'} />;

    if (!items?.length) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    return (
        <div className={'row w-100 mx-auto'}>
            <div className={`col-lg-2 p-0 ${isMobile ? 'mt-3 w-75 mx-auto text-center' : ''}`}
                style={{ marginTop: '80px' }}
            >
                <BoardSideBar
                    currentItem={currentItem}
                    items={items}
                    setCurrentItem={setCurrentItem}
                />
            </div>

            {isMobile && <Hr classes={'mt-3 mb-0'} />}

            {currentItem &&
                <div className={'col-lg-10'}>
                    {currentItem.title === '' ?
                        <></>
                        :
                        <>
                        {!isMobile && <Hr styles={{ marginTop: '80px' }} />}

                        <H2 title={currentItem.title.toUpperCase()} mt={'30px'} />

                        {currentItem.id === 1 ?
                            <>
                            {user.isBoardAdmin &&
                                <BoardManager items={items} setCounterReload={setCounterReload} />
                            }
                            </>
                            :
                            <BoardItemDisplay item={currentItem} />
                        }
                        </>
                    }
                </div>
            }
        </div>
    )
}
