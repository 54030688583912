import { BsPeople, BsPinMap, BsClock, BsCalendar, BsPen, BsCardList } from 'react-icons/bs';
import { CalendarioCarritosPage } from './carritos/calendario/CalendarioCarritosPage';
import { getHeaders } from '../../services';
import { HermanosCarritosUpdateDisponibilidad } from './carritos/hermanos/HermanosCarritosUpdateDisponibilidad';
import { pointer } from '../../app-config';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import HermanosCarritosAutoRegistro from './carritos/hermanos/HermanosCarritosAutoregistro';
import HermanosCarritosPage from './carritos/hermanos/HermanosCarritosPage';
import HorariosCarritosPage from './carritos/horarios/HorariosCarritosPage';
import PuntosCarritosPage from './carritos/puntos/PuntosCarritosPage';
import SolicitudesCarritosPage from './carritos/solicitudes/SolicitudesCarritosPage';
import TurnosCarritosPage from './carritos/turnos/TurnosCarritosPage';

export const CartsPage = () => {
  type typeCarritosSections =
    | ''
    | 'hermanos'
    | 'puntos'
    | 'horarios'
    | 'calendario'
    | 'turnos'
    | 'update-disponibilidad'
    | 'autoregistro'
    | 'solicitudes';

  const navigate = useNavigate();

  const getSection = (): typeCarritosSections => {
    const relativePath = window.location.pathname;
    if (relativePath === '/gestor-carritos/hermanos') return 'hermanos';
    if (relativePath === '/gestor-carritos/puntos') return 'puntos';
    if (relativePath === '/gestor-carritos/horarios') return 'horarios';
    if (relativePath === '/gestor-carritos/calendario') return 'calendario';
    if (relativePath === '/gestor-carritos/turnos') return 'turnos';
    if (relativePath === '/gestor-carritos/update-disponibilidad') return 'update-disponibilidad';
    if (relativePath === '/gestor-carritos/autoregistro') return 'autoregistro';
    if (relativePath === '/gestor-carritos/solicitudes') return 'solicitudes';
    return '';
  };

  const [currentSection, setCurrentSection] = useState<typeCarritosSections>(getSection());
  const [amountSolicitudes, setAmountSolicitudes] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const base =pointer.carritos;

  useEffect(() => {
    getAmountSolicitudes();
    setCurrentSection(getSection());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const getAmountSolicitudes = async () => {
    try {
      const response = await fetch(`${base}/solicitudes/amount`, {headers: getHeaders()});
      const data = await response.json();
      setAmountSolicitudes(data.amount);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching hermanos:', error);
      setIsLoading(false);
    }
  }

  return (
    <>
      <section className="my-4 p-4 mx-auto">
        {/*  {currentSection !== '' && (
          <div>
            <button
              className="btn btn-general-red btn-size12 d-flex align-items-center"
              onClick={() => navigate('/gestor-carritos/')}>
              <BsArrowLeft className="me-2" />
              Volver
            </button>
          </div>
        )} */}

        {currentSection === '' && (
          <div className="d-grid gap-3 m-auto mt-2" style={{ maxWidth: '600px' }}>
            <div className="text-center mt-3 mb-4 h1" style={{ fontSize: '3.5rem' }}>
              Carritos
            </div>
            <button
              onClick={() => navigate('/gestor-carritos/puntos')}
              className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center mt-4">
              <BsPinMap className="me-2" />
              Puntos
            </button>
            <button
              onClick={() => navigate('/gestor-carritos/horarios')}
              className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center">
              <BsClock className="me-2" />
              Horarios
            </button>
            <button
              onClick={() => navigate('/gestor-carritos/hermanos')}
              className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center">
              <BsPeople className="me-2" />
              Hermanos
            </button>

            <button
              onClick={() => navigate('/gestor-carritos/solicitudes')}
              className={'btn btn-size12 d-flex align-items-center justify-content-center ' + (amountSolicitudes > 0 ? 'btn-general-red' : 'btn-general-blue')} 
              >
              <BsCardList className="me-2" /> 
              {amountSolicitudes > 0 ? `Solicitudes pendientes:  (${amountSolicitudes})`  : 'No hay solicitudes pendientes'}
            </button>

            <button
              onClick={() => navigate('/gestor-carritos/calendario')}
              className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center">
              <BsCalendar className="me-2" /> Calendario
            </button>
            <button
              onClick={() => navigate('/gestor-carritos/turnos')}
              className="btn btn-general-red btn-size12 d-flex align-items-center justify-content-center"
              style={{ height: '65px' }}>
              <BsPen className="me-2" />
              <h3 className="mb-0">Asignacion de turnos</h3>
            </button>
          </div>
        )}
      </section>

      {currentSection === 'turnos' && (
        <div className="card shadow mx-auto">
          <TurnosCarritosPage />
        </div>
      )}

      {currentSection === 'horarios' && (
        <div className="card shadow mx-auto">
          <HorariosCarritosPage />
        </div>
      )}

      {currentSection === 'puntos' && (
        <div className="card shadow mx-auto">
          <PuntosCarritosPage />
        </div>
      )}

      {currentSection === 'hermanos' && (
        <div className="card shadow mx-auto">
          <HermanosCarritosPage />
        </div>
      )}

      {currentSection === 'calendario' && (
        <div className="card shadow mx-auto">
          <CalendarioCarritosPage />
        </div>
      )}

      {currentSection === 'update-disponibilidad' && (
        <div className="card shadow mx-auto">
          <HermanosCarritosUpdateDisponibilidad
            onCancel={() => setCurrentSection('hermanos')}
            onSave={() => setCurrentSection('hermanos')}
          />
        </div>
      )}

      {currentSection === 'autoregistro' && (
        <div className="card shadow mx-auto">
          <HermanosCarritosAutoRegistro
          />
        </div>
      )}

      {currentSection === 'solicitudes' && (
        <div className="card shadow mx-auto">
          <SolicitudesCarritosPage />
        </div>
      )}
    </>
  );
};
