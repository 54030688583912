import React, { useState, useEffect } from 'react';
import { pointer } from '../../../../app-config';
import {
  typeDisponibilidad,
  typeDisponibilidadForm,
  typeHermano,
  typeHorario,
  typePunto
} from '../../../../models/carritos';
import { getHeaders } from '../../../../services';
import { Loading } from '../../../commons';
import { useGetCurrentUser } from '../../../commons/custom-hooks';
import DisponibilidadCarritosForm from '../carritos-common/DisponibilidadCarritosForm';

const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];
const base = pointer.carritos;

type Props = {
  hermanoToEdit?: typeHermano;
  onSave: (hermano: typeHermano) => void;
  onCancel: () => void;
};

export const HermanosCarritosUpdateDisponibilidad: React.FC<Props> = ({ hermanoToEdit, onSave, onCancel }) => {
  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [horarios, setHorarios] = useState<typeHorario[]>([]);
  const [hermanos, setHermanos] = useState<typeHermano[]>([]);
  const [selectedHermano, setSelectedHermano] = useState<typeHermano | null>(null);
  const [disponibilidad, setDisponibilidad] = useState<typeDisponibilidad[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Por defecto es true
  const user = useGetCurrentUser();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const puntosResponse = await fetch(`${base}/puntos`, {headers: getHeaders()});
        const puntosData = await puntosResponse.json();
        setPuntos(puntosData.puntos);

        const horariosResponse = await fetch(`${base}/horarios`, {headers: getHeaders()});
        const horariosData = await horariosResponse.json();
        setHorarios(horariosData.horarios);

        await getHermanos();
        // Después de actualizar hermanos, se detecta el hermano por email
        detectHermanoByEmail(user.email);
 

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.email]);

  const getHermanos = async (): Promise<void> => {
    try {
      const hermanosResponse = await fetch(`${base}/hermanos`);
      const data = await hermanosResponse.json();
      setHermanos(data.hermanos);
    } catch (error) {
      console.error('Error fetching hermanos', error);
    }
  };

  const detectHermanoByEmail = (email: string) => {
    let hermano = hermanos.find((h) => h.user.email === email);
    if (hermano) setSelectedHermano(hermano);
  };

  useEffect(() => {
    if (selectedHermano) {
      setDisponibilidad(selectedHermano.disponibilidad);
    }
  }, [selectedHermano]);

  const handleAddDisponibilidad = (newDisponibilidad: typeDisponibilidadForm) => {
    const { punto, dia, horario } = newDisponibilidad;
    const newDisponibilidadList = [...disponibilidad];
    const puntoIndex = newDisponibilidadList.findIndex((d) => d.punto === punto);

    if (puntoIndex !== -1) {
      const diaIndex = newDisponibilidadList[puntoIndex].horarios.findIndex((h) => h.dia === dia);
      if (diaIndex !== -1) {
        if (!newDisponibilidadList[puntoIndex].horarios[diaIndex].horarios.includes(horario)) {
          newDisponibilidadList[puntoIndex].horarios[diaIndex].horarios.push(horario);
        } else {
          alert('El horario ya existe para este día en este punto.');
        }
      } else {
        newDisponibilidadList[puntoIndex].horarios.push({ dia, horarios: [horario] });
      }
    } else {
      newDisponibilidadList.push({
        punto,
        horarios: [{ dia, horarios: [horario] }]
      });
    }

    setDisponibilidad(newDisponibilidadList);
  };

  const handleRemoveDisponibilidad = (puntoIndex: number, diaIndex: number, horarioIndex: number) => {
    const newDisponibilidad = [...disponibilidad];
    newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.splice(horarioIndex, 1);
    if (newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.length === 0) {
      newDisponibilidad[puntoIndex].horarios.splice(diaIndex, 1);
    }
    if (newDisponibilidad[puntoIndex].horarios.length === 0) {
      newDisponibilidad.splice(puntoIndex, 1);
    }
    setDisponibilidad(newDisponibilidad);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedHermano) {
      const updatedHermano = {
        ...selectedHermano,
        disponibilidad
      };

      try {
        const response = await fetch(`${base}/hermanos/${selectedHermano.user.id}`, {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(updatedHermano)
        });

        if (!response.ok) {
          throw new Error('Error al actualizar el hermano');
        }

        const result = await response.json();
        onSave(updatedHermano as typeHermano);
        console.log('Hermano actualizado exitosamente:', result);
      } catch (error) {
        console.error('Error al actualizar los datos:', error);
      }
    }
  };

  if (isLoading) {
    return (
      <div style={{ padding: '15px' }}>
        <Loading />{' '}
      </div>
    );
  }

  return (
    <div className="container p-2">
      <form onSubmit={handleSubmit}>
        <div className="mb-3 row">
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="form-label">Hermano: </label>
            <h3>{selectedHermano?.name + ' ' + selectedHermano?.lastName}</h3>
          </div>
        </div>

        <DisponibilidadCarritosForm
          puntos={puntos}
          dias={dias}
          horarios={horarios}
          disponibilidad={disponibilidad}
          onAddDisponibilidad={handleAddDisponibilidad}
          onRemoveDisponibilidad={handleRemoveDisponibilidad}
        />

        <div className="d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary mt-3"
            disabled={!selectedHermano || disponibilidad.length === 0}>
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};
