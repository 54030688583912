// SkeletonLoader.jsx
import React from 'react';
import PropTypes from 'prop-types';

type Props = {
    width: string;
    height: string;
};

export const SkeletonLoader: React.FC<Props> = ({ width, height }) => {
  const loaderStyle = {
    width,
    height,
  };

  return <div className="skeleton-loader" style={loaderStyle}></div>;
};

SkeletonLoader.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default SkeletonLoader;
