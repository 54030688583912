import { typeTurno } from '.';

export type typeRole = 1 | 2 | 3 | 4 | 5 | 6;

export type typeUser = {
    cartAssignments?: typeTurno[];
    congregation: number;
    email: string;
    hthAssignments: number[];
    id: number;
    isActive: boolean;
    isAdmin: boolean;            // role 1
    isAuth: boolean;
    isBoardAdmin: boolean;       // role 6
    isBuildingsAdmin: boolean;   // role 5
    isCartsAdmin: boolean;       // role 4
    isHthAdmin: boolean;         // role 2
    isSuperAdmin?: boolean;
    isTelephonicAdmin: boolean;  // role 3
    phoneAssignments: number[];
    phoneNumber?: string;
    phoneNumberRequestTimestamp?: number;
    roles: typeRole[];
    rolesAsText: string;
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export const unauthenticatedUser: typeUser = {
    congregation: 0,
    email: "",
    hthAssignments: [],
    id: 0,
    isActive: false,
    isAdmin: false,
    isAuth: false,
    isBoardAdmin: false,
    isBuildingsAdmin: false,
    isCartsAdmin: false,
    isHthAdmin: false,
    isTelephonicAdmin: false,
    phoneAssignments: [],
    roles: [],
    rolesAsText: ''
}
