import { getHTHTerritoryService, maskTheBlock } from '../../services'
import { H2, Hr, Loading } from '../commons'
import { HTHAllBuildings, HTHAllDoNotCalls } from '../house-to-house'
import { SERVER, socketIoEvents } from '../../app-config'
import { setValuesAndOpenAlertModalReducer } from '../../store'
import { Socket, io } from 'socket.io-client'
import { typeBlock, typeHTHTerritory, typeTerritoryNumber } from '../../models'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useGetIsMobile, useIdleTimeout } from '../commons/custom-hooks'
import { useLocation, useNavigate } from 'react-router'

const socket: Socket = io(SERVER, { withCredentials: true })

export const HTHSearchPage = () => {
    const queryString = new URLSearchParams(useLocation().search);
    const block = queryString.get('manzana') as typeBlock|null;
    const congregation = parseInt(queryString.get('grupo') || '0');
    const usingLettersForBlocks = queryString.get('cambiar') === 'true';
    const territoryNumber = queryString.get('lugar') as typeTerritoryNumber;
    const isMobile = useGetIsMobile();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [territoryHTH, setTerritoryHTH] = useState<typeHTHTerritory|null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useIdleTimeout(5*60*1000, () => (() => navigate('/')));

    const refreshHTHTerritoryHandler = (): void => {
        if (!congregation || isNaN(congregation) || !territoryNumber)
            return
        getHTHTerritoryService(territoryNumber, congregation, block).then(response => {
            setIsLoading(false)
            if (!response) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron recuperar los datos; tal vez no haya internet",
                    animation: 2
                }))
                return
            }
            if (response.notSharedToday) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "Este edificio no fue compartido hoy por un capitán de salida",
                    animation: 2
                }))
                return
            }
            if (!response.hthTerritory) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron recuperar los datos",
                    animation: 2
                }))
                return
            }
            setTerritoryHTH(response.hthTerritory)
        })
    }

    useEffect(() => {
        refreshHTHTerritoryHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        socket.on(socketIoEvents.hthChange, (congregation0: number, territoryNumber0: typeTerritoryNumber) => {
            if (!congregation0 || congregation0 !== congregation) return;
            if (!territoryNumber0 || territoryNumber0 !== territoryNumber) return;
            console.log("Refrescando por uso de un usuario")
            setTimeout(() => {
                refreshHTHTerritoryHandler()
            }, Math.floor(Math.random() * 2000))
        })
        return () => { socket.off(socketIoEvents.hthChange) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [congregation, territoryNumber])

    if (isLoading) return <Loading mt={'80px'} />

    return (<>
        {territoryHTH &&
            <div>
                <H2 title={`T${territoryNumber} ${!!block ? `Manzana ${maskTheBlock(block, usingLettersForBlocks)}` : ''}`} />

                <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />

                <HTHAllDoNotCalls
                    hthTerritory={territoryHTH}
                />

                <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />

                <HTHAllBuildings
                    block={block}
                    congregation={congregation}
                    territoryHTH={territoryHTH}
                    usingLettersForBlocks={usingLettersForBlocks}
                />
            </div>
        }
    </>)
}
