import { BsCardList, BsPlus, BsShare } from 'react-icons/bs';
import { Card, Modal } from 'react-bootstrap';
import { DOMAIN, pointer } from '../../../../app-config';
import { getHeaders } from '../../../../services';
import { typeSolicitudRegistro } from '../../../../models/carritos';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { WhatsappShareButton } from 'react-share';
import SkeletonLoader from '../../../commons/SkeletonLoader';

export default function SolicitudesCarritosPage() {
  const [solicitudes, setSolicitudes] = useState<typeSolicitudRegistro[]>([]);
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolicitud, setSelectedSolicitud] = useState<typeSolicitudRegistro | null>(null);
  const navigate = useNavigate();

  const base = pointer.carritos;

  useEffect(() => {
    setIsLoading(true);
    fetch(`${base}/solicitudes`, { headers: getHeaders() })
      .then((response) => response.json())
      .then((data) => {
        let pending = data.solicitudes.filter((s: typeSolicitudRegistro) => s.status === 'pending');
        setSolicitudes(pending);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }, []);

  const handleOpenModal = (solicitud: typeSolicitudRegistro) => {
    setSelectedSolicitud(solicitud);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedSolicitud(null);
    setShowModal(false);
  };

  const handleAddToForm = () => {
    if (selectedSolicitud) {
      navigate('/gestor-carritos/hermanos', { state: { solicitud: selectedSolicitud } });
    }
  };

  return (
    <div className="container mt-3" style={{ maxWidth: '850px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="text-center flex-grow-1">
          {'Listado de solicitudes'} <BsCardList />
        </h3>
        <div>
          <WhatsappShareButton
            onClick={() => {}}
            url={`${DOMAIN}/gestor-carritos/autoregistro`}
            windowWidth={660}
            windowHeight={460}>
            <button className="btn btn-general-blue mb-4 mt-2">
              Compartir invitación {'  '}
              <BsShare></BsShare>
              
            </button>
          </WhatsappShareButton>
        </div>
      </div>
      <div className="text-center">
        <p>Listado de hermanos que han solicitado sumarse</p>
      </div>

      {!showFormAdd && (
        <div className="row justify-content-center">
          {isLoading && (
            <div className="col-12 d-flex flex-wrap gap-3 mb-3">
              <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
              <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
              <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
            </div>
          )}
          {!isLoading &&
            solicitudes.map((solicitud, index) => (
              <div className='d-flex justify-content-center flex-wrap flex-direction-column'>
                <Card
                  key={index}
                  className="mb-3 custom-card"
                  style={{ width: '20rem' }}
                  onClick={() => handleOpenModal(solicitud)}>
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <div>
                      <Card.Title className="mb-0">
                        {solicitud.name} {solicitud.lastName}
                      </Card.Title>
                    </div>
                    <div className="d-flex gap-2"></div>
                  </Card.Body>
                </Card>
              </div>
            ))}

          {!isLoading && solicitudes.length === 0 && (
            <div className="text-center">
              <div className="card p-3 mb-3 custom-card">
                <strong>No hay solicitudes pendientes</strong>
              </div>
            </div>
          )}
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Detalles de {selectedSolicitud?.name} {selectedSolicitud?.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSolicitud && (
            <div>
              <div className="mb-3">
                <strong>Nombre:</strong> {selectedSolicitud.name}
              </div>
              <div className="mb-3">
                <strong>Apellido:</strong> {selectedSolicitud.lastName}
              </div>
              <div className="mb-3">
                <strong>Participó:</strong> {selectedSolicitud.participo ? 'Sí' : 'No'}
              </div>
              <div className="mb-4">
                <strong>Comentarios:</strong>{' '}
                <div>{selectedSolicitud.comentarios ? selectedSolicitud.comentarios : 'Sin comentarios'}</div>
              </div>
              <div className="mb-2">
                <strong>Disponibilidad:</strong>
              </div>
              {selectedSolicitud.disponibilidad.map((disponibilidad, index) => (
                <div key={index}>
                  <h5>{disponibilidad.punto}</h5>
                  <ul>
                    {disponibilidad.horarios.map((horario, idx) => (
                      <li key={idx}>
                        {horario.dia}: {horario.horarios.join(', ')}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-general-blue w-100" onClick={handleAddToForm}>
            Agregar al listado <BsPlus></BsPlus>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
