import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserFromLSService, setConfigToLSService, setUserToLSService } from '../services/localStorageServices';
import { logoutService } from '../services';
import { typeUser, unauthenticatedConfig, unauthenticatedUser } from '../models';

const uls = getUserFromLSService();

export const userSlice = createSlice({
    name: 'user',
    initialState: uls ?? unauthenticatedUser,
    reducers: {
        logoutReducer: (state) => {
            setUserToLSService(unauthenticatedUser)
            setConfigToLSService(unauthenticatedConfig)
            logoutService()
            state = unauthenticatedUser
            return state
        },
        refreshUserReducer: (state, action: PayloadAction<typeUser>) => {
            setUserToLSService(action.payload)
            state = action.payload
            return state
        }
    }
});

export const { logoutReducer, refreshUserReducer } = userSlice.actions;
