import { Document, Page } from 'react-pdf';
import { FaArrowLeft, FaArrowRight, FaDownload } from 'react-icons/fa';
import { FC, useState } from 'react';
import { Hr, Loading } from '../commons';
import { typeBoardItem, typeItemBoardFile } from '../../models';
import { useGetIsMobile } from '../commons/custom-hooks';

type propsType = {
    item: typeBoardItem;
}

export const BoardItemDisplay: FC<propsType> = ({ item }) => {
    return (
        <>
            <style>{`.react-pdf__Page__canvas {width: 100% !important; height: auto !important;}`}</style>
            <div>
                {!!item.files?.length && item.files.map(file =>
                    <File key={file.date}
                        file={file}
                    />
                )}
            </div>
        </>
    )
}

const File = ({ file }: { file: typeItemBoardFile }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const isMobile = useGetIsMobile();

    return (
        <div key={file.date} className={'w-100 h-auto overflow-hidden mt-5'} style={{ backgroundColor: 'lightgrey' }}>
            <Hr />
            {!!numPages &&
                <div className={'d-flex justify-content-between align-items-center my-2 mx-5'}>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        onClick={() => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))}
                        disabled={pageNumber <= 1}
                    >
                        <FaArrowLeft /> Anterior
                    </button>
                    <span className={'mx-2'}>Página {pageNumber} de {numPages}</span>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        onClick={() => setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages))}
                        disabled={pageNumber >= numPages}
                    >
                        Siguiente <FaArrowRight />
                    </button>
                </div>
            }
            <div className={'text-center my-2'}>
                <a href={URL.createObjectURL(new Blob([new Uint8Array(file.data)]))}
                    className={'btn btn-general-red btn-sm py-2 px-3 '}
                    download={file.originalName}
                >
                    <FaDownload className={'mb-1 me-2'} /> Descargar
                </a>
            </div>
            <div className={'m-3'}>
                <Document
                    // file={{ data: new Uint8Array(file.data) }}
                    file={{ url: URL.createObjectURL(new Blob([new Uint8Array(file.data)], { type: 'application/pdf' })) }}
                    loading={<Loading mt={'90px'} mb={'50px'} />}
                    onLoadSuccess={e => setNumPages(e.numPages)}
                    
                >
                    <Page className={'w-100 h-auto'} pageNumber={pageNumber} scale={1.5} />
                </Document>
            </div>
        </div>
    )
}
