import { useEffect, useRef } from 'react'

const htmlContent = `
    <!DOCTYPE html>
    <html lang="es">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Gestor de Asignaciones</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
            <link rel="stylesheet" href="/gestor-asignaciones/styles.css">
        </head>
        <body>
            <gestor-asignaciones></gestor-asignaciones>
            <script src="/gestor-asignaciones/runtime.js"></script>
            <script src="/gestor-asignaciones/polyfills.js"></script>
            <script src="/gestor-asignaciones/main.js"></script>
        </body>
    </html>
`;

export const AssignmentManager = () => {

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    useEffect(() => {
        const iframeDoc = iframeRef.current?.contentDocument || iframeRef.current?.contentWindow?.document;
        if (!iframeDoc) return;
        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();
    }, []);

    return (
        <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '100vw', border: 'none' }}
            title="Gestor de Asignaciones"
        ></iframe>
    )
}
