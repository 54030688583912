import { getHeaders } from '../../../../services';
import { Loading } from '../../../commons';
import { pointer } from '../../../../app-config';
import { typeDisponibilidad, typeDisponibilidadForm, typeHorario, typePunto } from '../../../../models/carritos';
import { useGetCurrentUser } from '../../../commons/custom-hooks';
import { useNavigate } from 'react-router';
import DisponibilidadCarritosForm from '../carritos-common/DisponibilidadCarritosForm';
import React, { useState, useEffect } from 'react';

const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const base = pointer.carritos;

// Estos registros llegan a SolicitudesCarritosPage
export default function HermanosCarritosAutoRegistro() {
  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [horarios, setHorarios] = useState<typeHorario[]>([]);
  const [disponibilidad, setDisponibilidad] = useState<typeDisponibilidad[]>([]);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [participo, setParticipo] = useState(false);
  const [comentarios, setComentarios] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const user = useGetCurrentUser();

  const getPuntosService = async (): Promise<typePunto[]> => {
    try {
      const response = await fetch(`${base}/puntos`, {headers: getHeaders()});
      if (!response.ok) throw new Error('Error al obtener puntos');
      const data = await response.json();
      setPuntos(data.puntos);
      return data.puntos;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getHorariosService = async (): Promise<typeHorario[]> => {
    try {
      const response = await fetch(`${base}/horarios`, {headers: getHeaders()});
      if (!response.ok) throw new Error('Error al obtener horarios');
      const data = await response.json();
      setHorarios(data.horarios);
      return data.horarios;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  
 const checkSolicitudExistente = async () => {
    try {
      const response = await fetch(`${base}/solicitudes?email=${user.email}`, {headers: getHeaders()});
      if (!response.ok) throw new Error('Error al obtener solicitudes');
      const data = await response.json();
      return data.solicitudes;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchPuntosAndHorarios = async () => {
      await getPuntosService();
      await getHorariosService();
      setIsLoading(false);
    };
    fetchPuntosAndHorarios();
  }, []);

  const handleAddDisponibilidad = (newDisponibilidadInput: typeDisponibilidadForm) => {
    const { punto, horario, dia } = newDisponibilidadInput;

    setDisponibilidad((prevDisponibilidad) => {
      const newDisponibilidad = [...prevDisponibilidad];
      const puntoIndex = newDisponibilidad.findIndex((d) => d.punto === punto);

      if (puntoIndex !== -1) {
        const diaIndex = newDisponibilidad[puntoIndex].horarios.findIndex((h) => h.dia === dia);

        if (diaIndex !== -1) {
          if (!newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.includes(horario)) {
            newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.push(horario);
          } else {
            alert('El horario ya existe para este día en este punto.');
          }
        } else {
          newDisponibilidad[puntoIndex].horarios.push({ dia, horarios: [horario] });
        }
      } else {
        newDisponibilidad.push({ punto, horarios: [{ dia, horarios: [horario] }] });
      }

      return newDisponibilidad;
    });
  };

  const clearForm = () => {
    setName('');
    setLastName('');
    setParticipo(false);
    setDisponibilidad([]);
    setComentarios('');
  };

  const handleDeleteDisponibilidad = (puntoIndex: number, diaIndex: number, horarioIndex: number) => {
    setDisponibilidad((prevDisponibilidad) => {
      const newDisponibilidad = [...prevDisponibilidad];
      newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.splice(horarioIndex, 1);
      if (newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.length === 0) {
        newDisponibilidad[puntoIndex].horarios.splice(diaIndex, 1);
      }
      if (newDisponibilidad[puntoIndex].horarios.length === 0) {
        newDisponibilidad.splice(puntoIndex, 1);
      }
      return newDisponibilidad;
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCreate();
  };

  const handleCreate = async () => {
    const newSolicitud = {
      name,
      lastName,
      participo,
      disponibilidad,
      user,
      comentarios,
      status: 'pending'
    };
    console.log(newSolicitud);

    try {
      const response = await fetch(`${base}/solicitudes`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newSolicitud)
      });

      if (!response.ok) {
        throw new Error('Error al agregar Solicitud');
      }

      //Ruteo a home
      navigate('/');

      const result = await response.json();
      console.log('Solicitud agregada exitosamente:', result);
      //TODO: agregar redirect a home
      clearForm();
    } catch (error) {
      console.error('Error al enviar los datos:', error);
    }
  };

  return (
    <div className="container p-4">
      <div className="text-center mb-4 mt-3">
        <h2>Solicitud para participar en los carritos</h2>
        <p className='text-muted'>INVITAMOS A INSCRIBIRSE (2 Timoteo 4:5 )</p>
      </div>
      {isLoading ? <Loading /> : 
      <form onSubmit={handleSubmit}>
        <div className="mb-3 row">
          <div className="col-md-6 col-sm-12">
            <label className="form-label">Nombre</label>
            <input
              placeholder="Ingrese nombre"
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <label className="form-label">Apellido</label>
            <input
              placeholder="Ingrese apellido"
              type="text"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 col-sm-12 mt-4">
            <label className="form-label">Participo actualmente de la predicación con carritos</label>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="participoSi"
                name="participo"
                value="true"
                checked={participo === true}
                onChange={(e) => setParticipo(true)}
              />
              <label className="form-check-label" htmlFor="participoSi">Sí</label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="participoNo"
                name="participo"
                value="false"
                checked={participo === false}
                onChange={(e) => setParticipo(false)}
              />
              <label className="form-check-label" htmlFor="participoNo">No</label>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mt-4">
            <label className="form-label">Comentarios adicionales</label>
            <input
              type="text"
              className="form-control"
              value={comentarios}
              placeholder='Ingrese comentarios, sugerencias, etc(opcional)'
              onChange={(e) => setComentarios(e.target.value)}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <DisponibilidadCarritosForm
            puntos={puntos}
            dias={dias}
            horarios={horarios}
            disponibilidad={disponibilidad}
            onAddDisponibilidad={handleAddDisponibilidad}
            onRemoveDisponibilidad={handleDeleteDisponibilidad}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            disabled={!name || !lastName || !disponibilidad.length}
            type="submit"
            style={{ width: '50%' }}
            className="btn btn-general-blue mt-3 mb-3 btn-block btn-lg">
            Enviar solicitud
          </button>
        </div>
      </form>
}
    </div>
  );
}
