import { goToTop } from '../../services'
import { useEffect } from 'react'
import { useGetIsDarkMode } from '../commons/custom-hooks'

export const AboutUsPage = () => {
    const isDarkMode = useGetIsDarkMode();

    useEffect(() => goToTop(), [])

    return (
        <div className={isDarkMode ? 'text-white' : ''} style={{ marginTop: '80px' }}>

            <h2 className={'text-center'}>
                <strong>SOBRE NOSOTROS</strong>
            </h2>

            <p className='text-uppercase'><strong>Misericordia Web</strong></p>

            <p>Bienvenidos a <strong>Misericordia Web</strong>, una plataforma educativa sin fines de lucro dedicada a la enseñanza y la distribución geográfica de campañas de aprendizaje. Nuestra misión es facilitar el contacto entre quienes quieren enseñar y quienes quieren aprender, creando un entorno colaborativo donde la educación y el crecimiento personal son accesibles para todos.
            </p>
            
            <br/>
            <p className='text-uppercase'><strong>Nuestra Misión</strong></p>

            <p>En <strong>Misericordia Web</strong> creemos en el poder transformador de la educación y la colaboración. Nos enfocamos en:</p>
            
            <ul>
                <li className='mb-2'>Organización de Grupos de Enseñanza: Facilitamos la creación y gestión de grupos educativos que se organizan en función de la ubicación geográfica de los participantes. Utilizamos mapas y tecnología de geolocalización para optimizar la distribución de tareas y recursos.</li>
            
                <li className='mb-2'>Acceso a la Educación para Todos: Nos esforzamos por brindar acceso a herramientas educativas a comunidades que de otro modo podrían no tener acceso a ellas.</li>
            
                <li>Automatización y Autogestión: Ofrecemos la opción de inscripción de número celular para que los usuarios puedan recibir recordatorios de sus turnos de manera automatizada, promoviendo la autogestión de la participación.</li>
            </ul>
            
            <br/>
            <p className='text-uppercase'><strong>Nuestros Valores</strong></p>

            <p>Transparencia: Contamos con una política de privacidad y términos de uso claramente definidos para garantizar la seguridad y confianza de nuestros usuarios.</p>

            <p>Innovación Educativa: Utilizamos la tecnología para mejorar continuamente nuestros métodos y herramientas educativas.</p>

            <p>Compromiso Comunitario: Nos dedicamos a fortalecer las comunidades a través del aprendizaje colaborativo y la distribución equitativa de recursos.</p>
            
            <br/>
            <p className='text-uppercase'><strong>Por Qué Elegirnos</strong></p>
            
            <p><strong>Educación Sin Fronteras:</strong> Nuestra plataforma no solo organiza grupos locales, sino que también conecta a educadores y estudiantes de diferentes regiones, creando una amplia red de aprendizaje.</p>
            
            <p><strong>Tecnología Avanzada:</strong> Utilizamos la última tecnología en mapas y geolocalización para asegurar que cada tarea y recurso educativo llegue a donde más se necesita.</p>
            
            <p><strong>Apoyo Constante:</strong> Brindamos soporte continuo a nuestros usuarios, asegurándonos de que tengan todas las herramientas necesarias para aprovechar al máximo nuestra plataforma.</p>
            
            <br/>
            <p className='text-uppercase'><strong>Cómo Funcionamos</strong></p>
            
            <p><strong>Registro y Participación:</strong> Los usuarios pueden registrarse fácilmente en nuestra plataforma y optar por inscribir su número celular para recibir el material necesario y recordatorios automáticos sobre sus turnos y actividades.</p>
            
            <p><strong>Creación de Grupos:</strong> Los educadores pueden crear grupos basados en la ubicación geográfica de los participantes, optimizando el uso de recursos y tiempo.</p>
            
            <p><strong>Distribución de Tareas:</strong> Utilizamos mapas y geolocalización para asignar tareas de manera eficiente, asegurando que todos los participantes estén informados y organizados.</p>
            
            <br/>
            <p className='text-uppercase'><strong>Contáctanos</strong></p>
            
            <p>Estamos aquí para responder cualquier pregunta y recibir tus comentarios. Puedes ponerte en contacto con nosotros a través de nuestro correo electrónica, nuestros representantes o a través de nuestras redes sociales.</p>
            
            <p>Gracias por considerar <strong>Misericordia Web</strong>. Estamos comprometidos con la educación y la mejora continua de nuestras comunidades a través de la tecnología y la colaboración.</p>

        </div>
    )
}
