import { BsClock, BsPen, BsTrash } from 'react-icons/bs';
import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { typeHorario } from '../../../../models/carritos';
import { useEffect, useState } from 'react';
import HorariosCarritosAgregarPage from './HorariosCarritosAgregarPage';
import SkeletonLoader from '../../../commons/SkeletonLoader';

export default function HorariosCarritosPage() {
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [horariosList, setHorariosList] = useState<typeHorario[]>([]);
  const [horarioToEdit, setHorarioToEdit] = useState<typeHorario | null>(null);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const base = pointer.carritos;

  useEffect(() => {
    setIsLoading(true);
    fetch(`${base}/horarios`, {headers: getHeaders()})
      .then((response) => response.json())
      .then((data) => {
        setHorariosList(data.horarios);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }, []);

  const toggleFormAdd = () => {
    setShowFormAdd(!showFormAdd);
    if (showFormAdd) {
      setHorarioToEdit(null);
      setEditIndex(null);
    }
  };

  const handleSaveHorario = (horario: typeHorario, index: number | null) => {
    if (index !== null) {
      const updatedHorarios = horariosList?.map((h, i) => (i === index ? horario : h));
      setHorariosList(updatedHorarios);
    } else {
      setHorariosList([...horariosList, horario]);
    }
    setShowFormAdd(false);
    setHorarioToEdit(null);
    setEditIndex(null);
  };

  const handleEditHorario = (index: number) => {
    setHorarioToEdit(horariosList[index]);
    setEditIndex(index);
    setShowFormAdd(true);
  };

  const handleDeleteHorario = async (horario: typeHorario, index: number) => {
   
    try {
      const response = await fetch(`${base}/horarios`, {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(horario)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      if (response.ok) {
        const newHorariosList = horariosList.filter((_, i) => i !== index);
        setHorariosList(newHorariosList);
      }

      const data = await response.json();
      console.log('Horario eliminado:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container mt-3" style={{ maxWidth: '650px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="text-center flex-grow-1">
          {!showFormAdd ? 'Listado de horarios' : 'Agregar horario'} <BsClock />
        </h3>
        <button className="btn btn-general-blue" onClick={toggleFormAdd}>
          {showFormAdd ? 'Ver listado' : 'Agregar horario'}
        </button>
      </div>

      {!showFormAdd && (
        <div className="row">
            {isLoading && <div className="col-12 d-flex flex-wrap gap-3 mb-3">
            <SkeletonLoader width="98%" height="145px"></SkeletonLoader>
            <SkeletonLoader width="98%" height="145px"></SkeletonLoader>
            <SkeletonLoader width="98%" height="145px"></SkeletonLoader>
            </div>}
          {!isLoading && horariosList?.map((horario, index) => (
            <div key={index} className="col-12 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Horario {index + 1}</h5>
                  <p className="card-text">
                    {horario.start} - {horario.end}
                  </p>
                </div>
                <div className="card-footer d-flex gap-4 justify-content-end">
                  <button className="btn btn-general-blue" onClick={() => handleEditHorario(index)}>
                    Editar <BsPen />
                  </button>
                  <button className="btn btn-danger" onClick={() => handleDeleteHorario(horario, index)}>
                    Eliminar <BsTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {showFormAdd && (
        <HorariosCarritosAgregarPage onSave={handleSaveHorario} horarioToEdit={horarioToEdit} editIndex={editIndex} />
      )}
    </div>
  );
}
