import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { typeHorario } from '../../../../models/carritos';
import React, { useState, useEffect } from 'react';

type Props = {
  onSave: (horario: typeHorario, index: number | null) => void;
  horarioToEdit: typeHorario | null;
  editIndex: number | null;
};

export default function HorariosCarritosAgregarPage({ onSave, horarioToEdit, editIndex }: Props) {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const base = pointer.carritos;

  useEffect(() => {
    if (horarioToEdit) {
      setStart(horarioToEdit.start);
      setEnd(horarioToEdit.end);
    }
  }, [horarioToEdit]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newHorario = { start, end };

    if (editIndex === null) {
      try {
        const response = await fetch(`${base}/horarios`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(newHorario)
        });

        if (!response.ok) {
          throw new Error('Error al agregar el horario');
        }

        const data = await response.json();
        console.log('Horario agregado:', data);
        onSave(newHorario, null);
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      try {
        const response = await fetch(`${base}/horarios`, {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify({start: newHorario.start, end: newHorario.end, id: horarioToEdit?.id} )
        });

        if (!response.ok) {
          throw new Error('Error en la solicitud');
        }

        const data = await response.json();
        console.log('Horario actualizado:', data);
        onSave(newHorario, editIndex);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    setStart('');
    setEnd('');
  };

  return (
    <form onSubmit={handleSubmit} className="mt-3">
      <div className="mb-3">
        <label className="form-label">Hora de inicio</label>
        <input type="time" className="form-control" value={start} onChange={(e) => setStart(e.target.value)} required />
      </div>
      <div className="mb-3">
        <label className="form-label">Hora de fin</label>
        <input type="time" className="form-control" value={end} onChange={(e) => setEnd(e.target.value)} required />
      </div>
      <div className="d-flex justify-content-center mb-4 mt-4">
        <button disabled={!start || !end} type="submit" className="btn btn-general-blue ">
          {editIndex !== null ? 'Guardar Cambios' : 'Agregar Horario'}
        </button>
      </div>
    </form>
  );
}
