import { Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { FC, useState } from 'react';
import { isValidPhoneNumber, userPhoneNumberRegistrationService } from '../../../services';
import { Loading } from '../../commons';
import { setValuesAndOpenAlertModalReducer } from '../../../store';
import { typeUser } from '../../../models';
import { useDispatch } from 'react-redux';
import { useGetIsMobile } from '../../commons/custom-hooks';

type propsType = {
    closeModal: () => void;
    currentUser: typeUser;
}

export const UsersPhoneNumberModal: FC<propsType> = ({ closeModal, currentUser }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');  // one time password
    const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber ?? '+54911');
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();

    const invite = () => {
        setErrorMessage('');
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: `Se va a enviar un mensaje de WhatsApp a ${phoneNumber} para invitar a ${currentUser.email} a inscribir su número de teléfono en la aplicación, recibir notificaciones automatizadas y utilizar el asistente virtual`,
            mode: 'confirm',
            animation: 3,
            execution: async () => {
                setIsLoading(true);
                const response = await userPhoneNumberRegistrationService('add', currentUser.id, currentUser.email, phoneNumber, null);
                setIsLoading(false);
                if (!!response?.alreadyExists) {
                    setErrorMessage('Este teléfono ya está siendo usado por un usuario de la aplicación y no pueden repetirse');
                } else if (!response?.success) {
                    setErrorMessage('Algo salió mal... verificar que el número de teléfono sea el correcto. Mirar los logs.');
                }
            }
        }));
    }

    const confirm = async () => {
        setErrorMessage('');
        if (otp.length !== 4) return;
        setIsLoading(true);
        const response = await userPhoneNumberRegistrationService('confirm', currentUser.id, currentUser.email, null, otp);
        setIsLoading(false);
        if (!response?.success) {
            setErrorMessage('Algo salió mal... verificar que el código sea el correcto. Mirar los logs.');
            return;
        }
        closeModal();
    }

    const remove = async () => {
        setErrorMessage('');
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: `Se va a eliminar el número de celular ${phoneNumber} de ${currentUser.email} y ya no podrá recibir notificaciones automatizadas ni utilizar el asistente virtual`,
            mode: 'confirm',
            animation: 3,
            execution: async () => {
                setIsLoading(true);
                const response = await userPhoneNumberRegistrationService('delete', currentUser.id, currentUser.email, null, null);
                setIsLoading(false);
                if (!response?.success) {
                    setErrorMessage('No se pudo eliminar... Mirar los logs.');
                    return;
                }
                closeModal();
            }
        }));
    }

    return (
        <Modal
            fullscreen={'md-down'}
            onHide={closeModal}
            show={true}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title> Teléfono móvil de {currentUser.email} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ maxWidth: '500px', padding: isMobile ? '35px 30px 0' : '35px 0 0' }}>

                    <FloatingLabel
                        className={'mb-3 text-dark'}
                        label={"Teléfono celular"}
                    >
                        <Form.Control
                            type={'text'}
                            className={'form-control'}
                            placeholder={""}
                            value={phoneNumber}
                            onChange={e => e.target.value.length < 16 ? setPhoneNumber(e.target.value) : null}
                            disabled={!!currentUser.phoneNumberRequestTimestamp}
                            autoFocus
                        />
                    </FloatingLabel>
                    {!currentUser.phoneNumberRequestTimestamp &&
                        <div>...sin espacios y con código de país y de área (+54911... y 8 números más)</div>
                    }

                    {isLoading && <Loading />}

                    {!!currentUser.phoneNumberRequestTimestamp ?
                        <>
                        {errorMessage ?
                            <div className={'bg-danger text-white p-2 mt-3'}>
                                {errorMessage}
                            </div>
                            :
                            <>
                            {!!currentUser.phoneNumberRequestTimestamp &&
                                <>
                                <FloatingLabel
                                    className={'mb-3 text-dark'}
                                    label={"Clave de 4 números enviada"}
                                >
                                    <Form.Control
                                        type={'text'}
                                        className={'form-control'}
                                        placeholder={""}
                                        value={otp}
                                        onChange={e => e.target.value.length < 5 ? setOtp(e.target.value) : null}
                                        autoFocus
                                    />
                                </FloatingLabel>
                                <div>El código enviado al usuario puede ser usado hasta: <br /> {new Date(currentUser.phoneNumberRequestTimestamp + 24*60*60*1000).toLocaleDateString()} {new Date(currentUser.phoneNumberRequestTimestamp + 24*60*60*1000).toLocaleTimeString()}</div>
                                <button
                                    className={'btn btn-general-blue d-block w-100 mt-5'}
                                    style={{ fontWeight: 'bolder', height: '50px' }}
                                    onClick={confirm}
                                    disabled={otp.length !== 4}
                                >
                                    CONFIRMAR INVITACIÓN
                                </button>
                                <button
                                    className={`btn btn-general-red d-block w-100 mt-4 mb-5`}
                                    style={{ fontWeight: 'bolder', height: '50px' }}
                                    onClick={closeModal}
                                >
                                    CANCELAR
                                </button>
                                </>
                            }
                            </>
                        }
                        </>
                        :
                        <>
                        {errorMessage &&
                            <div className={'bg-danger text-white p-2 mt-3'}>
                                {errorMessage}
                            </div>
                        }
                        <button
                            className={'btn btn-general-blue d-block w-100 mt-5'}
                            style={{ fontWeight: 'bolder', height: '50px' }}
                            onClick={invite}
                            disabled={!isValidPhoneNumber(phoneNumber)}
                        >
                            ENVIAR INVITACIÓN {!!currentUser.phoneNumber && 'PARA REEMPLAZAR ESTE NÚMERO'}
                        </button>
                        {!!currentUser.phoneNumber &&
                            <button
                                className={`btn btn-general-red d-block w-100 mt-4`}
                                style={{ fontWeight: 'bolder', height: '50px' }}
                                onClick={remove}
                            >
                                ELIMINAR NÚMERO DE TELÉFONO
                            </button>
                        }
                        <button
                            className={`btn btn-general-red d-block w-100 mt-4 mb-5`}
                            style={{ fontWeight: 'bolder', height: '50px' }}
                            onClick={closeModal}
                        >
                            CANCELAR
                        </button>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
