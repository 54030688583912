import { BsCalendar, BsEye } from 'react-icons/bs';
import { getHeaders } from '../../../../services';
import { Modal, Button } from 'react-bootstrap';
import { pointer } from '../../../../app-config';
import { typeHorario, typePunto, typeTurno } from '../../../../models/carritos';
import { useGetIsMobile } from '../../../commons/custom-hooks';
import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../../commons/SkeletonLoader';
import TurnosCarritosAgregarModal from '../turnos/TurnosCarritosAgregarModal';

const daysOfWeekDesktop = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const daysOfWeekMobile = ['L', 'M', 'Mi', 'J', 'V', 'S'];

export const CalendarioCarritosPage = () => {
  const isMobile = useGetIsMobile();
  const [turnos, setTurnos] = useState<typeTurno[]>([]);
  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [horarios, setHorarios] = useState<typeHorario[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTurno, setSelectedTurno] = useState<typeTurno | null>(null);
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string>(() => {
    const today = new Date();
    return `${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
  });
  const [auxSelectedMonth, setAuxSelectedMonth] = useState<string>(selectedMonth);
  const [propsNewTurno, setPropsNewTurno] = useState<any | null>(null);
  const [showModalAddTurno, setShowModalAddTurno] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const base = pointer.carritos;

  useEffect(() => {
    if (isMobile) {
      setDaysOfWeek(daysOfWeekMobile);
    } else {
      setDaysOfWeek(daysOfWeekDesktop);
    }
  }, [isMobile]);

  useEffect(() => {
    fetchData();
    const handleResize = () => {
      let w = window.innerWidth;
      if (w < 768) {
        setSmallScreen(true);
        setDaysOfWeek(daysOfWeekMobile);
      } else {
        setSmallScreen(false);
        setDaysOfWeek(daysOfWeekDesktop);
      }
    };

    window.addEventListener('resize', handleResize);

    // Llamar a handleResize para configurar el estado inicial
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const turnosResponse = await fetch(`${base}/turnos`, {headers: getHeaders()});
      const turnosData = await turnosResponse.json();

      const horariosResponse = await fetch(`${base}/horarios`, {headers: getHeaders()});
      const horariosData = await horariosResponse.json();

      const puntosResponse = await fetch(`${base}/puntos`, {headers: getHeaders()});
      const puntosData = await puntosResponse.json();

      setTurnos(turnosData.turnos);
      setPuntos(puntosData.puntos?.map((punto: any) => punto));
      setHorarios(
        horariosData.horarios?.map((horario: any) => ({
          start: horario.start,
          end: horario.end
        }))
      );

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      setIsLoading(false);
    }
  };

  const handleTurnoClick = (turno: typeTurno) => {
    setSelectedTurno(turno);
    setShowModal(true);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputMonth = e.target.value;
    let formatMonth = inputMonth.split('-').reverse().join('-');
    setSelectedMonth(inputMonth);
    setAuxSelectedMonth(formatMonth);
  };

  const handleEmptyCellClick = (punto: string, day: string, horario: string, month: string) => {
    console.log(`Punto: ${punto}, Día: ${day}, Horario: ${horario}`);
    const propsNewTurno = {
      punto: punto,
      dia: day,
      horario: horario,
      mes: month
    };
    setPropsNewTurno(propsNewTurno);
    setShowModalAddTurno(true);
  };

  const renderCalendario = (punto: string, pickupCartPoint: string) => {
    return (
      <div key={punto} className="calendario mb-5 border rounded shadow-sm">
        <h3 className="text-center mb-3 mt-2">{punto}</h3>
        <div className="calendario-header d-flex border-bottom pb-2 mb-2">
          <div className="calendario-header-time flex-fill p-1"></div>
          {daysOfWeek?.map((day) => (
            <div key={day} className="calendario-header-day flex-fill text-center fw-bold border-start">
              {day}
            </div>
          ))}
        </div>
        <div className="calendario-body">
          {horarios?.map((horario, horarioIndex) => (
            <div key={horario.start} className="calendario-row d-flex align-items-start border-bottom py-2">
              <div className="calendario-time flex-fill text-center fw-bold border-end">
                <span>{horario.start}</span>
                <br />
                <span>{horario.end}</span>
              </div>
              {daysOfWeekDesktop?.map((day) => {
                const turnosFiltrados = turnos.filter(
                  (turno) =>
                    turno.punto === punto &&
                    turno.horario.dia === day &&
                    turno.horario.horario === horario.start + '-' + horario.end &&
                    turno.mes === auxSelectedMonth
                );
                return (
                  <div
                    key={day + horario.start}
                    className="calendario-cell flex-fill text-center border-start"
                    onClick={
                      turnosFiltrados.length === 0
                        ? () => handleEmptyCellClick(punto, day, `${horario.start}-${horario.end}`, auxSelectedMonth)
                        : undefined
                    }>
                    {turnosFiltrados.length > 0 ? (
                      turnosFiltrados?.map((turno, turnoIndex) =>
                        isMobile || smallScreen ? (
                          <div
                            key={`turno-${turnoIndex}`}
                            className="calendario-turno rounded p-2"
                            onClick={() => handleTurnoClick(turno)}>
                            <BsEye />
                          </div>
                        ) : (
                          <div
                            key={`turno-${turnoIndex}`}
                            className="calendario-turno rounded"
                            onClick={() => handleTurnoClick(turno)}>
                            {turno.hermanos?.map((hermano, hermanoIndex) => (
                              <div key={`hermano-${hermanoIndex}`}>
                                {hermano.name} {hermano.lastName}
                              </div>
                            ))}
                          </div>
                        )
                      )
                    ) : (
                      <div className="text-muted"></div>
                      
                    )}
                  </div>
                );
              })}
            </div>
          ))}
          <div>
            <div className="text-center mt-3 fw-bold">
              <p>Punto de retiro: {pickupCartPoint}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTurno(null);
  };

  const handleCloseModalAddTurno = () => {
    setShowModalAddTurno(false);
  }
  const handleCloseModalAddTurnoAndFetch = () => {
    setShowModalAddTurno(false);
    fetchTurnos();
  };

  const fetchTurnos = async () => {
    setIsLoading(true);
    const turnosResponse = await fetch(`${base}/turnos`, {headers: getHeaders()});
    const turnosData = await turnosResponse.json();
    setIsLoading(false);
    setTurnos(turnosData.turnos);
  }

  return (
    <div className="container mt-5">
      <h3>Calendario de turnos <BsCalendar></BsCalendar> </h3>
      <div className="mb-4">
        <label htmlFor="monthSelect" className="form-label">
          Selecciona el mes:
        </label>
        <input
          type="month"
          id="monthSelect"
          className="form-control"
          value={selectedMonth}
          onChange={handleMonthChange}
        />
        <div>
          <strong>Seleccionado:</strong> {selectedMonth}
        </div>
      </div>

      {isLoading && (
        <div className="mb-3">
          <SkeletonLoader width="100%" height="350px" />
        </div>
      )}

      {!isLoading && puntos?.map((punto) => renderCalendario(punto.name, punto.pickupCartPoint))}

      {showModalAddTurno && (
        <Modal show={showModalAddTurno} onHide={handleCloseModalAddTurno}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Turno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TurnosCarritosAgregarModal
              propsNewTurno={propsNewTurno}
              toggleFormAdd={handleCloseModalAddTurno}
              onAddTurno={handleCloseModalAddTurnoAndFetch}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalAddTurno}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {selectedTurno && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Información del Turno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Punto:</strong> {selectedTurno.punto}
            </p>
            <p>
              <strong>Horario:</strong> {selectedTurno.horario.dia}, {selectedTurno.horario.horario}
            </p>
            <p>
              <strong>Hermanos asignados:</strong>
            </p>
            <ul>
              {selectedTurno.hermanos?.map((hermano, index) => (
                <li key={index}>
                  {hermano.name} {hermano.lastName}
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
