import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { typePunto } from '../../../../models/carritos';
import React, { useState, useEffect } from 'react';

type Props = {
  puntoToEdit?: typePunto;
  onSave: (punto: typePunto) => void;
  onCancel: () => void;
};

const PuntosCarritosAgregarPage: React.FC<Props> = ({ puntoToEdit, onSave, onCancel }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [pickupCartPoint, setPickupCartPoint] = useState('');

  const base = pointer.carritos;

  useEffect(() => {
    if (puntoToEdit) {
      console.log(puntoToEdit);
      setName(puntoToEdit.name);
      setAddress(puntoToEdit.address);
      setPickupCartPoint(puntoToEdit.pickupCartPoint);
    } else {
      setName('');
      setAddress('');
      setPickupCartPoint('');
    }
  }, [puntoToEdit]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(puntoToEdit) {
      const updatedPunto: typePunto = { id: puntoToEdit.id, name, address, pickupCartPoint };
      handleUpdate(updatedPunto);
    } else {
      handleCreate(e);
    }
  };

  const handleCreate = async (e: React.FormEvent<HTMLFormElement>) => {
    const newPunto: typePunto = { name, address, pickupCartPoint };
    try {
      const response = await fetch(`${base}/puntos`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newPunto)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const data = await response.json();
      console.log('Punto creado:', data);
      onSave(newPunto);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleUpdate = async (puntoUpdated: typePunto) => {

    try {
      const response = await fetch(`${base}/puntos`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(puntoUpdated)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const data = await response.json();
      console.log('Punto actualizado:', data);
      onSave(puntoUpdated);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <form onSubmit={handleSubmit} className="container mt-3" style={{ maxWidth: '650px' }}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Nombre del punto
        </label>
        <input
          placeholder="Nombre del punto"
          type="text"
          className="form-control"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="address" className="form-label">
          Dirección
        </label>
        <input
          placeholder="Dirección exacta del Punto"
          type="text"
          className="form-control"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="pickupCartPoint" className="form-label">
          Punto de retiro
        </label>
        <input
          placeholder="Direccion de retiro del carrito"
          type="text"
          className="form-control"
          id="pickupCartPoint"
          value={pickupCartPoint}
          onChange={(e) => setPickupCartPoint(e.target.value)}
          required
        />
      </div>
      <div className="d-flex justify-content-center mb-4 mt-4">
        <button disabled={!name || !address || !pickupCartPoint} type="submit" className="btn btn-general-blue">
          {puntoToEdit ? 'Guardar cambios' : 'Agregar Punto'}
        </button>
        {puntoToEdit && (
          <button type="button" className="btn btn-secondary ms-2" onClick={onCancel}>
            Cancelar
          </button>
        )}
      </div>
    </form>
  );
};

export default PuntosCarritosAgregarPage;
