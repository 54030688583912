import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { setValuesAndOpenAlertModalReducer } from '../../../../store';
import { typeHermano, typeHorario, typePunto, typeTurno } from '../../../../models/carritos';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../../commons/SkeletonLoader';

type TurnosCarritosAgregarPageProps = {
  onAddTurno: (turno: typeTurno) => void;
  turnoToEdit?: typeTurno;
  toggleFormAdd: () => void;
};

const base = pointer.carritos;

const prioridadString = ['Alta', 'Media', 'Baja'];

const TurnosCarritosAgregarPage: React.FC<TurnosCarritosAgregarPageProps> = ({
  onAddTurno,
  turnoToEdit,
  toggleFormAdd
}) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [month, setMonth] = useState('');
  const [selectedPunto, setPunto] = useState(''); // Initial value set to the name of the first point
  const [selectedHorario, setHorario] = useState('');
  const [selectedDia, setDia] = useState('');
  const [selectedParticipantes, setSelectedParticipantes] = useState<typeHermano[]>([]);
  const [hermanosFiltrados, setHermanosFiltrados] = useState<typeHermano[]>([]);
  const [suggestions, setSuggestions] = useState([]);

  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [hermanos, setHermanos] = useState<typeHermano[]>([]);
  const [horarios, setHorarios] = useState<typeHorario[]>([]);
  const [turnos, setTurnos] = useState<typeTurno[]>([]);

  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      await Promise.all([fetchHermanos(), fetchPuntos(), fetchHorarios(), fetchTurnos()])
        .then(() => {
          setLoaded(true);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (turnoToEdit) {
      console.log(turnoToEdit);
      setMonth(turnoToEdit.mes);
      setPunto(turnoToEdit.punto);
      setHorario(turnoToEdit.horario.horario);
      setDia(turnoToEdit.horario.dia);
      setSelectedParticipantes(turnoToEdit.hermanos);
    }
    const loadDataAndGenerateSuggestions = async () => {
      await fetchData();
      generateTurnoSuggestions();
    };

    loadDataAndGenerateSuggestions();
  }, [loaded]);

  useEffect(() => {
    filterHermanosByRequeriments();
    setSelectedParticipantes([]);
  }, [selectedPunto, selectedDia, selectedHorario]);

  const fetchTurnos = async () => {
    try {
      const response = await fetch(`${base}/turnos`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching turnos');
      }
      const data = await response.json();
      setTurnos(data.turnos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchHorarios = async () => {
    try {
      const response = await fetch(`${base}/horarios`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching horarios');
      }
      const data = await response.json();
      setHorarios(data.horarios);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchHermanos = async () => {
    try {
      const response = await fetch(`${base}/hermanos`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching hermanos');
      }
      const data = await response.json();
      setHermanos(data.hermanos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchPuntos = async () => {
    try {
      const response = await fetch(`${base}/puntos`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching puntos');
      }
      const data = await response.json();
      setPuntos(data.puntos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const filterHermanosByRequeriments = () => {
    const hermanosFiltradosPorPunto = hermanos.filter((hermano) => {
      return hermano.disponibilidad.some((disponibilidad) => {
        return disponibilidad.punto === selectedPunto;
      });
    });

    const hermanosFiltradosPorDiaPunto = hermanosFiltradosPorPunto.filter((hermano) => {
      return hermano.disponibilidad.some((disponibilidad) => {
        return disponibilidad.horarios.some((horario) => {
          return horario.dia === selectedDia;
        });
      });
    });

    const hermanosFiltradosPorDiaPuntoHorario = hermanosFiltradosPorDiaPunto.filter((hermano) => {
      return hermano.disponibilidad.some((disponibilidad) => {
        return disponibilidad.horarios.some((horario) => {
          return horario.dia === selectedDia && horario.horarios.includes(selectedHorario.replaceAll('"', ''));
        });
      });
    });
    setHermanosFiltrados(hermanosFiltradosPorDiaPuntoHorario);
  };

  const handleAddTurno = async () => {
    const newTurno: typeTurno = {
      id: Date.now().toString(),
      mes: month,
      punto: selectedPunto,
      horario: { dia: selectedDia, horario: selectedHorario.replaceAll('"', '') },
      hermanos: selectedParticipantes
    };

    // Verificar si el turno ya existe
    const turnoExistente = turnos.find((turno) => {
      const { id, ...turnoSinId } = turno; // Copia sin el id
      return (
        turnoSinId.mes === newTurno.mes &&
        turnoSinId.punto === newTurno.punto &&
        turnoSinId.horario.dia === newTurno.horario.dia &&
        turnoSinId.horario.horario === newTurno.horario.horario &&
        turnoSinId.hermanos.length === newTurno.hermanos.length &&
        turnoSinId.hermanos.every((hermano, index) => hermano.user.id === newTurno.hermanos[index].user.id)
      );
    });

    console.log('Turnos:', turnos);
    console.log('Nuevo Turno:', newTurno);

    if (turnoExistente) {
      dispatch(
        setValuesAndOpenAlertModalReducer({
          mode: 'confirm',
          title: 'Atención',
          message: 'Ya existe un turno identico',
          animation: 2
        })
      );
      return;
    }

    // Verificar superposición de turnos
    const hermanosSuperpuestos: Set<string> = new Set();
    const superposicion = turnos.some((turno) => {
      const mismoHorario =
        turno.horario.dia === newTurno.horario.dia &&
        turno.horario.horario === newTurno.horario.horario &&
        turno.mes === newTurno.mes;

      turno.hermanos.forEach((hermano) => {
        const existe = newTurno.hermanos.some((nuevoHermano) => hermano.user.id === nuevoHermano.user.id);
        if (existe) {
          hermanosSuperpuestos.add(`${hermano.name} ${hermano.lastName}`);
        }
      });

      const hermanosEnComun = hermanosSuperpuestos.size > 0;
      return mismoHorario && hermanosEnComun;
    });

    console.log('Hermanos superpuestos:', Array.from(hermanosSuperpuestos));

    if (superposicion) {
      const mensajeSuperposicion = `Existe una superposición de turnos con los siguientes participantes: ${Array.from(
        hermanosSuperpuestos
      ).join(', ')}`;
      dispatch(
        setValuesAndOpenAlertModalReducer({
          mode: 'confirm',
          title: 'Atención',
          message: mensajeSuperposicion,
          animation: 2
        })
      );
      return;
    }

    try {
      const response = await fetch(`${base}/turnos`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newTurno)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const data = await response.json();
      console.log('Turno creado:', data);
      onAddTurno(newTurno);
    } catch (error) {
      console.error('Error:', error);
    }
    toggleFormAdd();
  };

  const handlePuntoChange = (e: any) => {
    setPunto(e.target.value);
  };

  const addRemoveParticipante = (hermano: typeHermano) => {
    let newParticipantes;

    if (selectedParticipantes.includes(hermano)) {
      newParticipantes = selectedParticipantes.filter((participante) => participante !== hermano);
    } else {
      newParticipantes = [...selectedParticipantes, hermano];
    }
    // Eliminar duplicados
    const uniqueParticipantes = Array.from(new Set(newParticipantes));
    setSelectedParticipantes(uniqueParticipantes);
  };

  const handleMonthChange = (e: any) => {
    setSelectedMonth(e);
    let month = '';
    const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
    if (!regex.test(e)) setMonth(e);
    const [year, monthNumber] = e.split('-');
    month = `${monthNumber}-${year}`;

    setMonth(month);
  };

  const generateTurnoSuggestions = () => {
    const suggestions: any = [];
    const disponibilidadMap = new Map();

    hermanos.forEach((hermano) => {
      hermano.disponibilidad.forEach((disponibilidad) => {
        disponibilidad.horarios.forEach((horario) => {
          const key = `${disponibilidad.punto}|${horario.dia}|${horario.horarios.join(',')}`;
          if (!disponibilidadMap.has(key)) {
            disponibilidadMap.set(key, []);
          }
          disponibilidadMap.get(key).push(hermano);
        });
      });
    });

    disponibilidadMap.forEach((hermanos, key) => {
      if (hermanos.length >= 2) {
        const [punto, dia, horario] = key.split('|');
        suggestions.push({
          punto,
          dia,
          horario,
          hermanos
        });
      }
    });

    console.log('sug', suggestions);
    setSuggestions(suggestions);
  };

  const handleSuggestionClick = (suggestion: any) => {
    setPunto(suggestion.punto);
    setDia(suggestion.dia);
    setHorario(JSON.stringify(suggestion.horario));
  };

  return (
    <div className="container mt-3 mb-3">
      {!loaded && <SkeletonLoader width="100%" height="400px"></SkeletonLoader>}
      {loaded && (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Mes</label>
                <input
                  type="month"
                  className="form-control"
                  value={selectedMonth}
                  onChange={(e) => handleMonthChange(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Punto</label>
                <select className="form-control" value={selectedPunto} onChange={handlePuntoChange}>
                  <option value="">Selecciona un punto</option>
                  {puntos.map((punto, index) => (
                    <option key={index} value={punto.name}>
                      {punto.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Día</label>
                <select className="form-control" value={selectedDia} onChange={(e) => setDia(e.target.value)}>
                  <option value="">Selecciona un día</option>
                  <option value="Lunes">Lunes</option>
                  <option value="Martes">Martes</option>
                  <option value="Miércoles">Miércoles</option>
                  <option value="Jueves">Jueves</option>
                  <option value="Viernes">Viernes</option>
                  <option value="Sabado">Sabado</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Horario</label>
                <select className="form-control" value={selectedHorario} onChange={(e) => setHorario(e.target.value)}>
                  <option value="">Selecciona un horario</option>
                  {horarios.map((horario, index) => (
                    <option key={index} value={JSON.stringify(horario.start + '-' + horario.end)}>
                      {horario.start}-{horario.end}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                {hermanosFiltrados.length > 0 && (
                  <>
                    <label className="form-label mt-2">
                      {' '}
                      <strong>Hermanos Disponibles:</strong>
                    </label>

                    <section className="d-flex justify-content-center align-items-center flex-column flex-wrap gap-4 ">
                      {hermanosFiltrados.map((hermano, index) => (
                        <div
                          className={`w-100 text-center card p-2 shadow cursor-pointer user-select-none c-pointer ${
                            selectedParticipantes.includes(hermano) ? 'bg-success text-white' : ''
                          }`}
                          key={index}
                          onClick={() => addRemoveParticipante(hermano)}>
                            <strong>

                          {hermano.name + ' ' + hermano.lastName + ' - ' + prioridadString[parseInt(hermano.prioridad ) - 1]}
                            </strong>
                        </div>
                      ))}
                    </section>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <div className="mb-3 text-center">
                  <h4>Sugerencias de Turnos</h4>
                </div>

                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {suggestions.map((suggestion: any, index) => (
                    <div
                      key={index}
                      className="card custom-card p-3 mb-2 hover"
                      onClick={() => handleSuggestionClick(suggestion)}>
                      <p>
                        <strong>Punto:</strong> {suggestion.punto}
                      </p>
                      <p>
                        <strong>Día:</strong> {suggestion.dia}
                      </p>
                      <p>
                        <strong>Horario:</strong> {suggestion.horario}
                      </p>
                      <strong>Hermanos disponibles:</strong>{' '}
                      <ul>
                        {suggestion.hermanos.map((hermano: typeHermano) => (
                          <li key={hermano.name + '_' + hermano.lastName}> {hermano.name + ' ' + hermano.lastName}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <button
              disabled={
                !selectedMonth || !selectedPunto || !selectedDia || !selectedHorario || selectedParticipantes.length < 2
              }
              className="btn btn-general-blue"
              onClick={handleAddTurno}>
              Agregar Turno
            </button>
            <button className="btn btn-secondary ms-2" onClick={toggleFormAdd}>
              Cancelar
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default TurnosCarritosAgregarPage;
