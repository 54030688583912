import { BsTrash } from 'react-icons/bs';
import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { typeTurno } from '../../../../models/carritos';
import React, { useEffect, useState } from 'react';
import SkeletonLoader from '../../../commons/SkeletonLoader';
import TurnosCarritosAgregarPage from './TurnosCarritosAgregarPage';

const TurnosCarritosPage: React.FC = () => {
  const [turnos, setTurnos] = useState<typeTurno[]>([]);
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [turnoToEdit, setTurnoToEdit] = React.useState<typeTurno | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const base = pointer.carritos;

  useEffect(() => {
    setIsLoading(true);
    const fetchTurnos = async () => {
      try {
        const response = await fetch(`${base}/turnos`, {headers: getHeaders()});
        const data = await response.json();
        setTurnos(data.turnos);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching turnos:', error);
        setIsLoading(false);
      }
    };
    fetchTurnos();
  }, []);

  const toggleFormAdd = () => {
    setShowFormAdd(!showFormAdd);
    setEditingIndex(null); // Reset editing index when toggling the form
  };

  const handleAddTurno = (newTurno: typeTurno) => {
    setTurnos([...turnos, newTurno]);
  };

  // const handleEdit = (turno: typeTurno) => {
  //   setTurnoToEdit(turno);
  //   toggleFormAdd();
  // };

  const handleUpdateTurno = (updatedTurno: typeTurno) => {
    if (editingIndex !== null) {
      const updatedTurnos = turnos?.map((turno, index) => (index === editingIndex ? updatedTurno : turno));
      setTurnos(updatedTurnos);
      setEditingIndex(null);
      setShowFormAdd(false);
    }
  };

  const getMonthName = (mes: string) => {
    const [month, year] = mes.split('-');
    const date = new Date(Number(year), Number(month) - 1); // Ajusta el mes restando 1
    return capitalizeFirstLetter(date.toLocaleString('es-ES', { month: 'long', year: 'numeric' }));
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const filteredTurnos = turnos?.filter((turno) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    const monthMatch = turno.mes.toLowerCase().includes(lowerSearchTerm);
    const monthStringMatch = getMonthName(turno.mes)?.toLowerCase().includes(lowerSearchTerm);
    const puntoMatch = turno.punto.toLowerCase().includes(lowerSearchTerm);
    const diaMatch = turno.horario.dia.toLowerCase().includes(lowerSearchTerm);
    const hermanoMatch = turno.hermanos.some((hermano) =>
      `${hermano.name.toLowerCase()} ${hermano.lastName.toLowerCase()}`.includes(lowerSearchTerm)
    );
    return puntoMatch || diaMatch || hermanoMatch || monthMatch || monthStringMatch;
  });

  const handleDeleteTurno = async (turno:typeTurno, index: number) => {
    try {
      const response = await fetch(`${base}/turnos`, {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(turno)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      if (response.ok) {
        setTurnos(turnos?.filter((_, i) => i !== index));
      }

      const data = await response.json();
      console.log('Turno eliminado:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  
  };

  return (
    <div className="container mt-3" style={{ maxWidth: '650px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="text-center flex-grow-1">{!showFormAdd ? 'Listado de turnos' : 'Agregar turno'}</h3>
        <button className="btn btn-general-blue" onClick={toggleFormAdd}>
          {showFormAdd ? 'Ver listado' : 'Agregar turno'}
        </button>
      </div>
      {!showFormAdd && (
        <div>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Buscar por punto, día o hermano..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      {!showFormAdd && (
        <div className="row">
      {isLoading && <div className="col-12 d-flex flex-wrap gap-3 mb-3">
            <SkeletonLoader width="98%" height="250px"></SkeletonLoader>
            <SkeletonLoader width="98%" height="250px"></SkeletonLoader>
            <SkeletonLoader width="98%" height="250px"></SkeletonLoader>
            </div>} 
          {filteredTurnos?.map((turno, index) => (
            <div key={index} className="col-12 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center">{turno.punto}</h5>
                  <p className="card-text">
                    Mes: <strong>{getMonthName(turno.mes)}</strong>
                  </p>
                  <p className="card-text">Dia: {turno.horario.dia}</p>
                  <p className="card-text">Horario: {turno.horario.horario.replaceAll('"', '')}</p>
                  <div className="card-text ">
                    Hermanos:
                    <ul>
                      {turno.hermanos?.map((hermano, index) => (
                        <li key={index}>
                          {hermano.name} {hermano.lastName}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="card-footer d-flex gap-4 justify-content-end">
            {/*       <button className="btn btn-general-blue" onClick={() => handleEdit(turno)}>
                    Editar <BsPen />
                  </button> */}
                  <button className="btn btn-danger" onClick={() => handleDeleteTurno(turno, index)}>
                    Eliminar <BsTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {showFormAdd && (
        <TurnosCarritosAgregarPage
          onAddTurno={editingIndex === null ? handleAddTurno : handleUpdateTurno}
          turnoToEdit={turnoToEdit || undefined}
          toggleFormAdd={toggleFormAdd}
        />
      )}
    </div>
  );
};

export default TurnosCarritosPage;
