import { FC } from 'react';
import { useGetIsDarkMode, useGetIsMobile } from './custom-hooks';

type propsType = {
    mb?: string;
    mt?: string;
    title: string;
}

export const H2: FC<propsType> = ({ mb, mt, title}) => {
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    return (
        <h2 className={`text-center ${isDarkMode ? 'text-white' : ''}`}
            style={{
                fontSize: isMobile ? '2.4rem' : '3.5rem',
                fontWeight: 'bolder',
                marginTop: mt ?? '80px',
                marginBottom: mb ?? ''
            }}
        >
            {title}
        </h2>
    )
}
