import { FC } from 'react'
import { useGetIsDarkMode } from './custom-hooks'

type propsType = {
    classes?: string
    styles?: object
}

export const Hr: FC<propsType> = ({ classes, styles }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <hr className={`${isDarkMode ? 'text-white ' : ''}${classes ? classes : ''}`}
            style={{ ...styles }}
        />
    )
}
