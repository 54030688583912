import { BsBackspace } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import { generalBlue } from '../../app-config'
import { useEffect, useState } from 'react'
import { useGetIsMobile } from './custom-hooks'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

export const ReturnBtn = () => {
    const [show, setShow] = useState(false);
    const isMobile = useGetIsMobile();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (['/', '/acceso', '/nuevo-usuario'].includes(location.pathname))
            return setShow(false)
        if (['edificio', 'buscador'].includes(location.pathname.split('/')[1]))
            return setShow(false)
        setShow(true)
    }, [location.pathname])

    if (!show) return <></>

    return (
        <div className={'mt-2'}
            style={{
                position: 'fixed',
                left: '0',
                marginLeft: '10px',
                marginTop: isMobile ? '0' : '0px',
                zIndex: 3
            }}
        >
            <Button size={isMobile ? 'sm' : undefined}
                style={{
                    backgroundColor: generalBlue,
                    border: '1px solid ' + generalBlue,
                    borderRadius:' 5px'
                }}
                onClick={() => navigate(-1)}
            >
                <BsBackspace style={{ paddingBottom: '3px' }} /> VOLVER &nbsp;
            </Button>
        </div>
    )
}
