import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { setValuesAndOpenAlertModalReducer } from '../../../../store';
import { typeHermano, typeHorario, typePunto, typeTurno } from '../../../../models/carritos';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';

type TurnosCarritosAgregarPageProps = {
  onAddTurno: (turno: typeTurno) => void;
  propsNewTurno?: any;
  toggleFormAdd: () => void;
};

const base = pointer.carritos;

const prioridadString = ['Alta', 'Media', 'Baja'];

const TurnosCarritosAgregarModal: React.FC<TurnosCarritosAgregarPageProps> = ({
  onAddTurno,
  propsNewTurno,
  toggleFormAdd
}) => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [month, setMonth] = useState('');
  const [selectedPunto, setPunto] = useState(''); // Initial value set to the name of the first point
  const [selectedHorario, setHorario] = useState('');
  const [selectedDia, setDia] = useState('');
  const [selectedParticipantes, setSelectedParticipantes] = useState<typeHermano[]>([]);
  const [hermanosFiltrados, setHermanosFiltrados] = useState<typeHermano[]>([]);

  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [hermanos, setHermanos] = useState<typeHermano[]>([]);
  const [horarios, setHorarios] = useState<typeHorario[]>([]);
  const [turnos, setTurnos] = useState<typeTurno[]>([]);

  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const formatMonthStart = (month: string) => {
    if (month.split('-')[1].length === 4) {
      const [a, b] = month.split('-');
      return `${b}-${a}`;
    } else {
      const [a, b] = month.split('-').reverse();
      return `${b}-${a}`;
    }
  };

  const formatMonthAdd = (month: string) => {
    if (month.split('-')[1].length === 4) {
      const [a, b] = month.split('-');
      return `${a}-${b}`;
    } else {
      const [a, b] = month.split('-').reverse();
      return `${a}-${b}`;
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([fetchHermanos(), fetchPuntos(), fetchHorarios(), fetchTurnos()])
        .then(() => {
          setLoaded(true);

          if (propsNewTurno) {
            console.log(propsNewTurno);
            let month = '';
            month = formatMonthStart(propsNewTurno.mes);
            console.log(month);
            setSelectedMonth(month);
            setPunto(propsNewTurno.punto);
            setHorario(JSON.stringify(propsNewTurno.horario));
            setDia(propsNewTurno.dia);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loaded]);

  useEffect(() => {
    filterHermanosByRequeriments();
    setSelectedParticipantes([]);
  }, [selectedPunto, selectedDia, selectedHorario]);

  const fetchTurnos = async () => {
    try {
      const response = await fetch(`${base}/turnos`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching turnos');
      }
      const data = await response.json();
      setTurnos(data.turnos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchHorarios = async () => {
    try {
      const response = await fetch(`${base}/horarios`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching horarios');
      }
      const data = await response.json();
      setHorarios(data.horarios);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchHermanos = async () => {
    try {
      const response = await fetch(`${base}/hermanos`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching hermanos');
      }
      const data = await response.json();
      setHermanos(data.hermanos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchPuntos = async () => {
    try {
      const response = await fetch(`${base}/puntos`, {headers: getHeaders()});
      if (!response.ok) {
        throw new Error('Error fetching puntos');
      }
      const data = await response.json();
      setPuntos(data.puntos);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const filterHermanosByRequeriments = () => {
    const hermanosFiltradosPorPunto = hermanos.filter((hermano) => {
      return hermano.disponibilidad.some((disponibilidad) => {
        return disponibilidad.punto === selectedPunto;
      });
    });

    const hermanosFiltradosPorDiaPunto = hermanosFiltradosPorPunto.filter((hermano) => {
      return hermano.disponibilidad.some((disponibilidad) => {
        return disponibilidad.horarios.some((horario) => {
          return horario.dia === selectedDia;
        });
      });
    });

    const hermanosFiltradosPorDiaPuntoHorario = hermanosFiltradosPorDiaPunto.filter((hermano) => {
      return hermano.disponibilidad.some((disponibilidad) => {
        return disponibilidad.horarios.some((horario) => {
          return horario.dia === selectedDia && horario.horarios.includes(selectedHorario.replaceAll('"', ''));
        });
      });
    });
    setHermanosFiltrados(hermanosFiltradosPorDiaPuntoHorario);
  };

  const handleAddTurno = async () => {
    const newTurno: typeTurno = {
      id: Date.now().toString(),
      mes: formatMonthAdd(selectedMonth),
      punto: selectedPunto,
      horario: { dia: selectedDia, horario: selectedHorario.replaceAll('"', '') },
      hermanos: selectedParticipantes
    };

    // Verificar si el turno ya existe
    const turnoExistente = turnos.find((turno) => {
      const { id, ...turnoSinId } = turno; // Copia sin el id
      return (
        turnoSinId.mes === newTurno.mes &&
        turnoSinId.punto === newTurno.punto &&
        turnoSinId.horario.dia === newTurno.horario.dia &&
        turnoSinId.horario.horario === newTurno.horario.horario &&
        turnoSinId.hermanos.length === newTurno.hermanos.length &&
        turnoSinId.hermanos.every((hermano, index) => hermano.user.id === newTurno.hermanos[index].user.id)
      );
    });

    console.log('Turnos:', turnos);
    console.log('Nuevo Turno:', newTurno);

    if (turnoExistente) {
      dispatch(
        setValuesAndOpenAlertModalReducer({
          mode: 'confirm',
          title: 'Atención',
          message: 'Ya existe un turno identico',
          animation: 2
        })
      );
      return;
    }

    // Verificar superposición de turnos
    const hermanosSuperpuestos: Set<string> = new Set();
    const superposicion = turnos.some((turno) => {
      const mismoHorario =
        turno.horario.dia === newTurno.horario.dia &&
        turno.horario.horario === newTurno.horario.horario &&
        turno.mes === newTurno.mes;

      turno.hermanos.forEach((hermano) => {
        const existe = newTurno.hermanos.some((nuevoHermano) => hermano.user.id === nuevoHermano.user.id);
        if (existe) {
          hermanosSuperpuestos.add(`${hermano.name} ${hermano.lastName}`);
        }
      });

      const hermanosEnComun = hermanosSuperpuestos.size > 0;
      return mismoHorario && hermanosEnComun;
    });

    console.log('Hermanos superpuestos:', Array.from(hermanosSuperpuestos));

    if (superposicion) {
      const mensajeSuperposicion = `Existe una superposición de turnos con los siguientes participantes: ${Array.from(
        hermanosSuperpuestos
      ).join(', ')}`;
      dispatch(
        setValuesAndOpenAlertModalReducer({
          mode: 'confirm',
          title: 'Atención',
          message: mensajeSuperposicion,
          animation: 2
        })
      );
      return;
    }

    try {
      const response = await fetch(`${base}/turnos`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newTurno)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const data = await response.json();
      console.log('Turno creado:', data);
      onAddTurno(newTurno);
    } catch (error) {
      console.error('Error:', error);
    }
    toggleFormAdd();
  };

  const handlePuntoChange = (e: any) => {
    setPunto(e.target.value);
  };

  const addRemoveParticipante = (hermano: typeHermano) => {
    let newParticipantes;

    if (selectedParticipantes.includes(hermano)) {
      newParticipantes = selectedParticipantes.filter((participante) => participante !== hermano);
    } else {
      newParticipantes = [...selectedParticipantes, hermano];
    }
    // Eliminar duplicados
    const uniqueParticipantes = Array.from(new Set(newParticipantes));
    setSelectedParticipantes(uniqueParticipantes);
  };

  const handleMonthChange = (e: any) => {
    setSelectedMonth(e);
    let month = '';
    const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
    if (!regex.test(e)) setMonth(e);
    const [year, monthNumber] = e.split('-');
    month = `${monthNumber}-${year}`;

    setMonth(month);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <div className="mb-3">
            <label className="form-label">Mes</label>
            <input
              type="month"
              className="form-control"
              value={selectedMonth}
              onChange={(e) => handleMonthChange(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Punto</label>
            <select className="form-control" value={selectedPunto} onChange={handlePuntoChange}>
              <option value="">Selecciona un punto</option>
              {puntos.map((punto, index) => (
                <option key={index} value={punto.name}>
                  {punto.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Día</label>
            <select className="form-control" value={selectedDia} onChange={(e) => setDia(e.target.value)}>
              <option value="">Selecciona un día</option>
              <option value="Lunes">Lunes</option>
              <option value="Martes">Martes</option>
              <option value="Miercoles">Miércoles</option>
              <option value="Jueves">Jueves</option>
              <option value="Viernes">Viernes</option>
              <option value="Sabado">Sabado</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Horario</label>
            <select className="form-control" value={selectedHorario} onChange={(e) => setHorario(e.target.value)}>
              <option value="">Selecciona un horario</option>
              {horarios.map((horario, index) => (
                <option key={index} value={JSON.stringify(horario.start + '-' + horario.end)}>
                  {horario.start}-{horario.end}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Hermanos Disponibles</label>
            <section className="d-flex justify-content-center align-items-center flex-column flex-wrap gap-4 ">
              {hermanosFiltrados.map((hermano, index) => (
                <div
                  className={`card p-2 w-100 text-center shadow cursor-pointer user-select-none c-pointer ${
                    selectedParticipantes.includes(hermano) ? 'bg-success text-white' : ''
                  }`}
                  key={index}
                  onClick={() => addRemoveParticipante(hermano)}>
                  <strong>
                    {hermano.name + ' ' + hermano.lastName + ' - ' + prioridadString[parseInt(hermano.prioridad)]}
                  </strong>
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <button
          disabled={
            !selectedMonth || !selectedPunto || !selectedDia || !selectedHorario || selectedParticipantes.length < 2
          }
          className="btn btn-general-blue w-100"
          onClick={handleAddTurno}>
          Agregar Turno
        </button>
      </div>
    </div>
  );
};

export default TurnosCarritosAgregarModal;
