import { Dispatch, FC, SetStateAction } from 'react';
import { typeBoardItem } from '../../models';
import { useGetIsMobile } from '../commons/custom-hooks';

type propsType = {
    currentItem: typeBoardItem | null;
    items: typeBoardItem[];
    setCurrentItem: Dispatch<SetStateAction<typeBoardItem | null>>;
}

export const BoardSideBar: FC<propsType> = ({ currentItem, items, setCurrentItem }) => {
    const isMobile = useGetIsMobile();

    return (
        <div className={'pt-1 pb-3 px-2'} style={{ backgroundColor: isMobile ? '' : 'lightgray' }}>
            <ul className={'list-group mt-3'}>
                {items && items.sort((i1, i2) => i1.order - i2.order).map(item =>
                    <li className={`list-group-item fw-bold pointer ${currentItem?.id === item.id ? 'active' : ''}`}
                        key={item.title}
                        onClick={() => setCurrentItem(item)}
                        onMouseOver={e => (e.target as Element).classList.add('bg-primary')}
                        onMouseOut={e => (e.target as Element).classList.remove('bg-primary')}
                    >
                        {item.title}
                    </li>
                )}
            </ul>
        </div>
    )
}
