import { BsMap, BsPen, BsTrash } from 'react-icons/bs';
import { getHeaders } from '../../../../services';
import { pointer } from '../../../../app-config';
import { typePunto } from '../../../../models/carritos';
import { useEffect, useState } from 'react';
import PuntosCarritosAgregarPage from './PuntosCarritosAgregarPage';
import SkeletonLoader from '../../../commons/SkeletonLoader';

export default function PuntosCarritosPage() {
  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [puntoToEdit, setPuntoToEdit] = useState<typePunto | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const base = pointer.carritos;

  useEffect(() => {
    setIsLoading(true);
    fetch(`${base}/puntos`, {headers: getHeaders()})
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPuntos(data.puntos);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }, []);

  const toggleFormAdd = () => {
    setShowFormAdd(!showFormAdd);
    setPuntoToEdit(null);
  };

  const handleEdit = (index: number) => {
    setPuntoToEdit(puntos[index]);
    setShowFormAdd(true);
  };

  const handleSave = (punto: typePunto) => {
    console.log(punto);
    if (puntoToEdit) {
      const updatedPuntos = puntos?.map((p) => (p === puntoToEdit ? punto : p));
      setPuntos(updatedPuntos);
    } else {
      setPuntos([...puntos, punto]);
    }
    setShowFormAdd(false);
    setPuntoToEdit(null);
  };

  const handleDelete = async (punto: typePunto, index: number) => {
    try {
      const response = await fetch(`${base}/puntos`, {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(punto)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      if (response.ok) {
        setPuntos(puntos.filter((_, i) => i !== index));
      }

      const data = await response.json();
      console.log('Punto eliminado:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container mt-3" style={{ maxWidth: '850px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="text-center flex-grow-1">
          {!showFormAdd ? 'Listado de puntos' : puntoToEdit ? 'Editar punto' : 'Agregar punto'} <BsMap />
        </h3>
        <button className="btn btn-general-blue" onClick={toggleFormAdd}>
          {showFormAdd ? 'Ver listado' : 'Agregar punto'}
        </button>
      </div>

      {!showFormAdd && (
        <div className="row">
          {isLoading && <div className="col-12 d-flex flex-wrap gap-3 mb-3">
            <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
            <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
            <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
            </div>}
            {!isLoading && puntos?.map((punto, index) => (
            <div key={index} className="col-12 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{punto.name}</h5>
                  <p className="card-text">Dirección: {punto.address}</p>
                  <p className="card-text">Punto de retiro: {punto.pickupCartPoint}</p>
                </div>
                <div className="card-footer d-flex gap-4 justify-content-end">
                  <button className="btn btn-general-blue" onClick={() => handleEdit(index)}>
                    Editar <BsPen />
                  </button>
                  <button className="btn btn-danger" onClick={() => handleDelete(punto, index)}>
                    Eliminar <BsTrash />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {showFormAdd && (
        <PuntosCarritosAgregarPage
          puntoToEdit={puntoToEdit || undefined}
          onSave={handleSave}
          onCancel={toggleFormAdd}
        />
      )}
    </div>
  );
}
