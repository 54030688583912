import { getHeaders } from '.';
import { getTokenFromLSService, removeTokenFromLSService, setTokenToLSService, setUserToLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeConfig, typeResponseData, typeUser, unauthenticatedConfig } from '../models';

const base: string = pointer.user

export const assignHTHTerritoryService = async (email: string, toAssign: number|null, toUnassign: number|null, all: boolean|null): Promise<typeUser|null> => {
    if (!getTokenFromLSService()) return null
    try {
        all = !!all
        const response = await fetch(`${base}/hth-assignment`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ email, toAssign, toUnassign, all })
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.success || !data.user) return null
        return data.user
    } catch (error) {
        console.log(error)
        return null
    }
}

export const assignTLPTerritoryService = async (email: string, toAssign: number|null, toUnassign: number|null, all: boolean|null): Promise<typeUser|null> => {
    if (!getTokenFromLSService()) return null
    try {
        all = !!all
        const response = await fetch(`${base}/tlp-assignment`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ email, toAssign, toUnassign, all })
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.success || !data.user) return null
        return data.user
    } catch (error) {
        console.log(error)
        return null
    }
}

export const changeEmailService = async (newEmail: string): Promise<typeResponseData|null> => {
    if (!getTokenFromLSService() && !newEmail) return null
    try {
        const response = await fetch(`${base}/token`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ newEmail })
        })
        const data: typeResponseData = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const changePswService = async (team: number, psw: string|null, newPsw: string, id: string|null): Promise<typeResponseData|null> => {
    // no token
    try {
        const response = await fetch(`${base}/token`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ team, psw, newPsw, id })
        })
        const data: typeResponseData = await response.json()
        if (data && data.success && data.newToken) setTokenToLSService(data.newToken)
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const changePswOtherUserService = async (email: string): Promise<[string, boolean]|null> => {
    if (!getTokenFromLSService()) return null
    try {
        const response = await fetch(`${base}/token`, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ email })
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.newPassword) return null
        data.emailSuccess = !!data.emailSuccess
        return [data.newPassword, data.emailSuccess]
    } catch (error) {
        console.log(error)
        return null
    }
}

export const deleteMyAccountService = async (): Promise<boolean> => {
    if (!getTokenFromLSService()) return false
    try {
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ myUser: true })
        })
        const data: typeResponseData = await response.json()
        return !!data?.success
    } catch (error) {
        console.log(error)
        return false
    }
}

export const deleteUserService = async (userId: number): Promise<boolean> => {
    if (!getTokenFromLSService()) return false
    try {
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ userId })
        })
        const data: typeResponseData = await response.json()
        return !!data?.success
    } catch (error) {
        console.log(error)
        return false
    }
}

export const editUserService = async (email: string, isActive: boolean, roles: number[]): Promise<typeUser|null> => {
    if (!getTokenFromLSService()) return null
    try {
        const response = await fetch(base, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ email, isActive, roles })
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.success || !data.user) return null
        return data.user
    } catch (error) {
        console.log(error)
        return null
    }
}

export const getEmailByEmailLink = async (congregation: string, id: string): Promise<string|null> => {
    try {
        const response = await fetch(`${base}/recovery?id=${id}&team=${congregation}`, {
            method: 'GET',
            headers: getHeaders()
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.success || !data.email) return null
        return data.email
    } catch (error) {
        console.log(error)
        return null
    }
}

export const getUserByTokenService = async (token: string = ''): Promise<{ user: typeUser|false|null, config: typeConfig|null }> => {
    if (!getTokenFromLSService()) return { user: false, config: null }
    try {
        const response = await fetch(base, {
            method: 'GET',
            headers: !!token ? { ...getHeaders(), 'x-authorization': token } : getHeaders()
        });
        const data: typeResponseData = await response.json();
        if (!data) return { user: false, config: null }
        if (!data.config) {
            data.config = unauthenticatedConfig;
        }
        if (!data?.success || !data?.user) {
            if (data && !data.success) removeTokenFromLSService();
            return { user: false, config: data.config }
        }
        data.user.isAuth = true;
        data.user.isAdmin = data.user.roles.includes(1) || !!data.user.isSuperAdmin;
        data.user.isHthAdmin = data.user.roles.includes(2) || data.user.isAdmin || !!data.user.isSuperAdmin;
        data.user.isTelephonicAdmin = data.user.roles.includes(3) || data.user.isAdmin || !!data.user.isSuperAdmin;
        data.user.isCartsAdmin = data.user.roles.includes(4) || data.user.isAdmin || !!data.user.isSuperAdmin;
        data.user.isBuildingsAdmin = data.user.roles.includes(5) || data.user.isHthAdmin || data.user.isAdmin || !!data.user.isSuperAdmin;
        data.user.isBoardAdmin = data.user.roles.includes(6) || data.user.isAdmin || !!data.user.isSuperAdmin;
        setUserToLSService(data.user);
        return {
            user: data.user,
            config: data.config
        }
    } catch (error) {
        console.log(error);
        return { user: null, config: null }
    }
}

export const getUsersService = async (): Promise<typeUser[]|null> => {
    if (!getTokenFromLSService()) return null
    try {
        const response = await fetch(`${base}/all`, {
            method: 'GET',
            headers: getHeaders()
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.success || !data.users) return null
        return data.users
    } catch (error) {
        console.log(error)
        return null
    }
}

export const loginService = async (email: string, password: string, recaptchaToken: string): Promise<typeResponseData|null> => {
    try {
        const response: any = await fetch(`${base}/token`, {
            method: 'POST',
            headers: getHeaders(recaptchaToken),
            body: JSON.stringify({ email, password })
        })
        const data: typeResponseData = await response.json()
        if (!!data?.success && !!data?.newToken) setTokenToLSService(data.newToken)
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const logoutAllService = async (): Promise<boolean> => {
    if (!getTokenFromLSService()) return false
    try {
        const response = await fetch(`${base}/token`, {
            method: 'DELETE',
            headers: getHeaders()
        })
        const data: typeResponseData = await response.json()
        if (!data || !data.success || !data.newToken) return false
        setTokenToLSService(data.newToken)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

export const logoutService = (): void => {
    removeTokenFromLSService()
}

export const registerUserService = async (team: number, email: string, invitationId: string, password: string, recaptchaToken: string): Promise<typeResponseData|null> => {
    try {
        const response = await fetch(base, {
            method: 'POST',
            headers: getHeaders(recaptchaToken),
            body: JSON.stringify({ email, invitationId, password, team })
        })
        const data: typeResponseData = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const registerUserAdminsService = async (email: string, password: string): Promise<typeResponseData|null> => {
    try {
        const response = await fetch(base, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email, password })
        })
        const data: typeResponseData = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const sendLinkToRecoverAccount = async (email: string): Promise<any> => {
    try {
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ email })
        })
        const data: typeResponseData = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const userPhoneNumberRegistrationService = async (action: 'add' | 'confirm' | 'delete' | 'replace', userId: number, userEmail: string, phoneNumber: string | null, otp: string | null): Promise<typeResponseData | null> => {
    try {
        const response = await fetch(`${base}/phone-number`, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({
                action,
                otp,
                phoneNumber,
                userEmail,
                userId
            })
        });
        const data: typeResponseData = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
