import { Navigate, Route, Routes } from 'react-router-dom';
import { useGetCurrentUser } from '../commons/custom-hooks';
import * as Pages from '../_pages';

export const MainRouter = () => {
    const user = useGetCurrentUser();

    return (
        <Routes>
            <Route path={'/'} element={ <Pages.HomePage /> } />
            <Route path={'/acceso'} element={<Pages.LoginPage />} />
            <Route path={'/buscador'} element={ <Pages.HTHSearchPage /> } />
            <Route path={'/edificio/:congregation/:territoryNumber/:block/:face/:streetNumber'} element={<Pages.HTHBuildingPage />} />
            <Route path={'/instalar'} element={ <Pages.InstallPage /> } />
            <Route path={'/nuevo-usuario'} element={ <Pages.NewUserPage /> } />
            <Route path={'/privacidad'} element={ <Pages.PrivacyPolicyPage /> } />
            <Route path={'/sobre-nosotros'} element={ <Pages.AboutUsPage /> } />
            <Route path={'/recovery'} element={ <Pages.RecoveryPage /> } />
            <Route path={'/servicio'} element={ <Pages.TermsOfServicePage /> } />
            {/* <Route path={'/celulares/:id'} element={ <Pages.CampaignPage /> } /> */}
            {/* <Route path={'/reunion'} element={ <Pages.LastMeetingPage /> } /> */}
            {/* <Route path={'/testing'} element={ <Pages.TestingPage /> } /> */}

            {user.isAuth &&
                <>
                    <Route path={'/tablero'} element={<Pages.BoardPage />} />
                    <Route path={'/casa-en-casa/:territoryNumber'} element={<Pages.HouseToHousePage />} />
                    <Route path={'/gestor-asignaciones'} element={<Pages.AssignmentManagerPage />} />
                    <Route path={'/novedades'} element={<Pages.NewsPage />} />
                    <Route path={'/selector'} element={<Pages.SelectorPage />} />
                    <Route path={'/telefonica/:territoryNumber'} element={<Pages.TelephonicPage />} />
                    <Route path={'/usuario'} element={<Pages.UserPage /> } />
                    <Route path={'/gestor-carritos/autoregistro'} element={<Pages.CartsPage />} />
                    <Route path={'/gestor-carritos/update-disponibilidad'} element={<Pages.CartsPage />} />
                </>
            }

            {user.isAdmin && <>
                <Route path={'/admins/config'} element={<Pages.AdminsPage />} />
                <Route path={'/gmail'} element={<Pages.GmailTokensPage />} />
            </>}

            {(user.isHthAdmin || user.isTelephonicAdmin || user.isCartsAdmin || user.isBoardAdmin) && <>
                <Route path={'/admins'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/logs'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/usuarios'} element={<Pages.AdminsPage />} />
            </>}
            
            {(user.isHthAdmin) && <>
                <Route path={'/admins/estadisticas-casa-en-casa'} element={<Pages.AdminsPage />} />
            </>}

            {(user.isTelephonicAdmin) && <>
                <Route path={'/admins/estadisticas-telefonica'} element={<Pages.AdminsPage />} />
            </>}

            {user.isCartsAdmin && <>
                <Route path={'/gestor-carritos'} element={<Pages.CartsPage />} />
                <Route path={'/gestor-carritos/:section'} element={<Pages.CartsPage />} />
            </>}

            <Route path={'/*'} element={ <Navigate to={'/'} replace /> } />
        </Routes>
    )
}
