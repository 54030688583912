import { getUserByTokenService, goToTop } from '../../services';
import { HouseToHouseSelector, TelephonicSelector } from '../selector';
import { Hr } from '../commons';
import { logoutReducer, refreshUserReducer, setConfigurationReducer } from '../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useGetCurrentUser } from '../commons/custom-hooks';
import { useNavigate } from 'react-router';

export const SelectorPage = () => {
    const user = useGetCurrentUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        goToTop();
        getUserByTokenService().then(response => {
            if (!response.user || !response.config) {
                if (response.user === false)
                    dispatch(logoutReducer());
                navigate('/acceso');
                return;
            }
            dispatch(refreshUserReducer(response.user));
            dispatch(setConfigurationReducer(response.config));
        })
    }, [dispatch, navigate]);

    return (
        <>
            {(user.isBuildingsAdmin || !!user.hthAssignments?.length) &&
                <>
                    <HouseToHouseSelector />

                    <Hr />
                    <Hr />
                </>
            }

            <TelephonicSelector />

            {/* <Hr />
            <Hr />

            <CampaignSelector /> */}
        </>
    )
}
