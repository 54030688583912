import { deleteMyAccountService, getUserByTokenService, logoutAllService } from '../../services';
import { H2, Hr } from '../commons';
import { logoutReducer, refreshUserReducer, setConfigurationReducer, setValuesAndOpenAlertModalReducer } from '../../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetCurrentUser, useGetIsDarkMode } from '../commons/custom-hooks';
import { useNavigate } from 'react-router';
import { UserChangeEmail } from '../user/UserChangeEmail';
import { UserChangePassword } from '../user/UserChangePassword';
import { UserPersonalData } from '../user/UserPersonalData';

export const UserPage = () => {
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    const [showChangePsw, setShowChangePsw] = useState(false);
    const [showPersonalData, setShowPersonalData] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const openAlertModalHandler = (title: string, message: string, animation?: number): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title,
            message,
            animation
        }));
    }

    const openConfirmModalHandler = (title: string, message: string, execution: Function): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title,
            message,
            execution
        }));
    }

    const logoutAll = async (): Promise<void> => {
        const success: boolean = await logoutAllService();
        if (!success)
            return openAlertModalHandler("Algo falló", "Intentar de nuevo", 2);
        openAlertModalHandler("Cierre exitoso", "", 1);
    }

    const deleteAccount = async (): Promise<void> => {
        const success: boolean = await deleteMyAccountService();
        if (!success)
            return openAlertModalHandler("Algo falló", "Intentar de nuevo", 2);
        window.location.reload();
    }

    useEffect(() => {
        getUserByTokenService().then(response => {
            if (!response.user || !response.config) {
                if (response.user === false) dispatch(logoutReducer());
                navigate('/acceso');
                return;
            }
            dispatch(refreshUserReducer(response.user));
            dispatch(setConfigurationReducer(response.config));
        });
    }, [dispatch, navigate]);

    return (
        <>
            <H2 title={'Usuario'} />

            {showPersonalData &&
                <UserPersonalData
                    closeModal={() => setShowPersonalData(false)}
                />
            }

            <div className={`card text-center ${isDarkMode ? 'bg-dark text-white' : ''}`}
                style={{ margin: '30px auto', maxWidth: '700px', padding: '25px' }}
            >
                
                <h1 className={'mt-2 py-2'}> {user.email} </h1>

                <Hr />

                <div className={'my-4'}>

                    <h3> Asignaciones de las Reuniones: </h3>

                    <h4> Ninguna </h4>
                </div>

                <Hr />
                
                <div className={'my-4'}>

                    <h3> Territorios de Casa en Casa asignados: </h3>

                    {!!user.hthAssignments?.length ?
                        ([...user.hthAssignments]).sort((a1, a2) => a1 - a2).map(territoryNumber =>
                            <button key={territoryNumber}
                                className={'btn btn-general-blue d-inline-block text-center mt-3 mx-1 px-0'}
                                onClick={() => navigate(`/casa-en-casa/${territoryNumber}`)}
                                style={{ fontWeight: 'bolder', width: '65px' }}
                            >
                                {territoryNumber}
                            </button>
                        )
                        :
                        <h4> Ninguno </h4>
                    }
                </div>

                <Hr />
                
                <div className={'my-4'}>

                    <h3> Territorios de Telefónica asignados: </h3>

                    {!!user.phoneAssignments?.length ?
                        [...user.phoneAssignments].sort((a1, a2) => a1 - a2).map(territoryNumber =>
                            <button key={territoryNumber}
                                className={'btn btn-general-red d-inline-block text-center mt-3 mx-1 px-0'}
                                onClick={() => navigate(`/telefonica/${territoryNumber}`)}
                                style={{ fontWeight: 'bolder', width: '65px' }}
                            >
                                {territoryNumber}
                            </button>
                        )
                        :
                        <h4> Ninguno </h4>
                    }
                </div>

                <Hr />

                <div className={'my-4'}>

                    <h3> Turnos de Carritos/Exhibidores: </h3>

                    {!!user.cartAssignments?.length ?
                        [...user.cartAssignments].map(ca =>
                            <button key={ca.id}
                                className={'btn btn-success d-inline-block text-center mt-3'}
                                style={{ fontWeight: 'bolder' }}
                                onClick={() => navigate(`/gestor-c/${ca}`)}
                            >
                                {ca.mes} {ca.horario.dia} {ca.horario.horario} con {ca.hermanos.find(x => x.user.id !== user.id)?.name} {ca.hermanos.find(x => x.user.id !== user.id)?.lastName} - Punto {ca.punto}
                            </button>
                        )
                        :
                        <h4> Ninguno </h4>
                    }
                </div>

                <Hr />

                {!showChangePsw && !showChangeEmail && <>
                    <button
                        className={'btn btn-general-blue py-2'}
                        onClick={() => setShowPersonalData(true)}
                        style={{ width: '350px', margin: '40px auto 0' }}
                    >
                        Ver mis datos en la aplicación
                    </button>

                    <button
                        className={'btn btn-general-blue py-2'}
                        onClick={() => setShowChangePsw(true)}
                        style={{ width: '350px', margin: '15px auto 0' }}
                    >
                        Cambiar contraseña
                    </button>

                    <button
                        className={'btn btn-general-blue py-2'}
                        onClick={() => setShowChangeEmail(true)}
                        style={{ width: '350px', margin: '15px auto 0' }}
                    >
                        Cambiar de dirección de email
                    </button>

                    <button
                        className={'btn btn-general-blue py-2'}
                        onClick={() => openConfirmModalHandler("¿Cerrar sesiones?", "Esta opción cerrará todas las sesiones en todos los dispositivos en que se haya ingresado excepto en este", logoutAll)}
                        style={{ width: '350px', margin: '15px auto 0' }}
                    >
                        Cerrar sesión en todos los dispositivos
                    </button>

                    <button
                        className={'btn btn-general-red py-2'}
                        onClick={() => openConfirmModalHandler("ELIMINAR LA CUENTA", "Esta opción ELIMINARÁ IRREVERSIBLEMENTE ESTA CUENTA. SE PERDERÁN TODOS LOS DATOS ASOCIADOS A ELLA Y NO PODRÁN SER RECUPERADOS.", deleteAccount)}
                        style={{ width: '350px', margin: '15px auto' }}
                    >
                        Eliminar mi cuenta
                    </button>
                </>}

            </div>


            {showChangePsw &&
                <UserChangePassword
                    openAlertModalHandler={openAlertModalHandler}
                    openConfirmModalHandler={openConfirmModalHandler}
                    setShowChangePsw={setShowChangePsw}
                />
            }

            {showChangeEmail &&
                <UserChangeEmail
                    openAlertModalHandler={openAlertModalHandler}
                    openConfirmModalHandler={openConfirmModalHandler}
                    setShowChangeEmail={setShowChangeEmail}
                />
            }
        </>
    )
}
