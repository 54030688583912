import { Dispatch, FC, SetStateAction, useState } from 'react';
import { getHeaders } from '../../services';
import { pointer } from '../../app-config';
import { typeBoardItem } from '../../models';
import { useGetIsMobile } from '../commons/custom-hooks';
import axios from 'axios';

type propsType = {
    currentItem: typeBoardItem;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardManagerUploadFile: FC<propsType> = ({ currentItem, setCounterReload }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const isMobile = useGetIsMobile();

    const handleUpload = async () => {
        if (!selectedFile) return;
        const formData = new FormData();
        formData.append('pdf', selectedFile);
        setUploadStatus('Subiendo...');
        try {
            const { data } = await axios.patch(`${pointer.board}?itemId=${currentItem.id}&itemTitle=${currentItem.title}`, formData, {
                headers: {
                    ...getHeaders(),
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (data.success) {
                setUploadStatus('Subido!');
                setCounterReload(x => x + 1);
            } else {
                setUploadStatus('Falló. Mirar los logs.');
            }
        } catch (error) {
            setUploadStatus(`Falló: ${error}`);
        }
    }

    return (
        <div className='container'>
            <h5>Subir un archivo PDF</h5>
            <div className={'row'}>
                <div className={'col-9'}>
                    <input
                        className={'form-control form-control-sm'}
                        type={'file'}
                        id={'formFile'}
                        accept={'application/pdf'}
                        onChange={e => {
                            setUploadStatus('');
                            const file = e.target.files?.[0];
                            if (file && file.type === 'application/pdf') {
                                setSelectedFile(file);
                                setUploadStatus('');
                            } else {
                                setSelectedFile(null);
                                setUploadStatus('Solo se aceptan PDFs por ahora');
                            }
                        }}
                    />
                </div>
                <div className={'col-3'}>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        onClick={handleUpload}
                        disabled={!selectedFile}
                    >
                        {isMobile ? 'Confirmar' : 'Confirmar Archivo'}
                    </button>
                </div>
            </div>
            {uploadStatus &&
                <div className={`mt-3 alert ${uploadStatus.includes('Subido!') ? 'alert-success' : 'alert-danger'}`}>
                    {uploadStatus}
                </div>
            }
        </div>
    )
}
