import { ButtonGroup, Container, ToggleButton } from 'react-bootstrap';
import { FC, Fragment, useMemo, useRef, useState } from 'react';
import { generalBlue, googleMapConfig } from '../../../app-config';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { Hr, Loading } from '../../commons';
import { typeHTHBuilding, typeHTHTerritory } from '../../../models';
import { useGetIsMobile } from '../../commons/custom-hooks';

type propsType = {
    hthTerritories: typeHTHTerritory[];
}

const radios = [
    { name: 'Fecha Puntual', value: '1' },
    { name: 'Rango de Fechas', value: '2' }
];

export const StatisticsHTHMap: FC<propsType> = ({ hthTerritories }) => {
    const [date1, setDate1] = useState<number | null>(null);
    const [date2, setDate2] = useState<number | null>(null);
    const [isSingleDate, setIsSingleDate] = useState<'1' | '2'>('1');
    const centerCoords = hthTerritories[0].map.centerCoords;
    const isMobile = useGetIsMobile();
    const map = useRef<any>();
    const mapLoader = useJsApiLoader(googleMapConfig);

    const buildings: typeHTHBuilding[] = useMemo(() => {
        let hs: typeHTHBuilding[] = [];
        if (!date1) return hs;
        if (isSingleDate === '2' && !date2) return hs;
        hthTerritories.forEach(t => {
            t.map.polygons.forEach(p => {
                p.buildings?.forEach(b => {
                    if (!!b.coords) {
                        if (isSingleDate === '1') {
                            if (b.households?.some(h => h.onDates?.some(d => d > date1 && d < date1 + 24*60*60*1000))) {
                                hs.push({ ...b, street: p.street, territoryNumber: t.territoryNumber });
                            }
                        } else {
                            if (b.households?.some(h => h.onDates?.some(d => d > date1 && d < (date2 ?? 1)))) {
                                hs.push({ ...b, street: p.street, territoryNumber: t.territoryNumber });
                            }
                        }
                    }
                });
            });
        });
        return hs;
    }, [date1, date2, hthTerritories, isSingleDate]);

    return (
        <div className={'mt-4'}>

            <Hr classes={'mt-5 mx-5'} />

            <div className={'row py-4'}>
                <div className={'col-4'}>
                    <Container className={'maxw-400 mt-4'}>
                        <ButtonGroup className={'w-100 mt-3 mb-2'}>
                            {radios.map((radio, idx) => (
                                <ToggleButton key={idx}
                                    type={'radio'}
                                    id={`radio-${idx}`}
                                    className={isSingleDate === radio.value ? 'btn btn-general-blue' : 'btn btn-secondary'}
                                    checked={isSingleDate === radio.value}
                                    onChange={e => {
                                        const newValue = e.currentTarget.value as '1' | '2';
                                        if (newValue === '1') {
                                            setDate2(null);
                                        } else {
                                            if (date1) {
                                                const adjustedDate = new Date(date1);
                                                adjustedDate.setHours(23, 59, 59, 999);
                                                setDate2(adjustedDate.getTime());
                                            }
                                        }
                                        setIsSingleDate(newValue);
                                    }}
                                    value={radio.value}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Container>
                </div>
                <div className={'col-4'}>
                    <label className={'form-label'}>
                        {isSingleDate === '1' ? 'Fecha' : 'Fecha Desde'}
                    </label>
                    <input type={'date'}
                        className={'form-control'}
                        value={date1 ? new Date(date1 - 3*60*60*1000).toISOString().split('T')[0] : ''}
                        onChange={e => {
                            const selectedDate = e.target.value ? new Date(e.target.value).getTime() + 3*60*60*1000: null;
                            setDate1(selectedDate);
                        }}
                    />
                </div>
                {isSingleDate === '2' && date1 &&
                    <div className={'col-4'}>
                        <label className={'form-label'}>
                            Fecha Hasta
                        </label>
                        <input type={'date'}
                            className={'form-control'}
                            value={date2 ? new Date(date2 - 3*60*60*1000).toISOString().split('T')[0] : ''}
                            onChange={e => {
                                const selectedDate = e.target.value ? new Date(e.target.value).getTime() + 3*60*60*1000 : null;
                                if (!selectedDate) return;
                                if (selectedDate < date1) {
                                    setDate2(date1);
                                } else {
                                    const adjustedDate = new Date(selectedDate);
                                    adjustedDate.setHours(23, 59, 59, 999);
                                    setDate2(adjustedDate.getTime());
                                }
                            }}
                        />
                    </div>
                }
            </div>

            {date1 && (date2 || isSingleDate === '1') &&
                <>
                    {!mapLoader.isLoaded && <Loading />}

                    {mapLoader.loadError && <h3> Falló: {mapLoader.loadError.message} </h3>}
                
                    <GoogleMap
                        id={googleMapConfig.id}
                        mapContainerClassName={isMobile ? 'position-absolute' : 'd-block m-auto'}
                        mapContainerStyle={{
                            height: isMobile ? '600px' : '500px',
                            width: '93%'
                        }}
                        onLoad={mapInstance => {map.current = mapInstance}}
                        center={centerCoords}
                        zoom={17.8}
                        // onZoomChanged={() => setZoom(map.current?.getZoom())}
                        // options={{ center: centerCoords, zoom: zoom }}
                        // zoom={zoom}
                    >
                        
                        {buildings.map((h, i) =>
                            <Marker key={i}
                                // options={{ anchorPoint: new google.maps.Point(centerCoords.lat, centerCoords.lng) }}
                                position={h.coords ?? { lat: 1, lng: 2 }}
                                onClick={() => console.log("h", h)}
                                clickable={true}
                                label={`T${h.territoryNumber} ${h.street} ${h.streetNumber}`}
                            />
                        )}

                        {hthTerritories.map(t => (
                            <div key={t.territoryNumber}>
                                {t.map.polygons.map(currentFace =>
                                    <Fragment key={currentFace.id}>
                                        <Polygon
                                            editable={false}
                                            draggable={false}
                                            path={[currentFace.coordsPoint1, currentFace.coordsPoint2, currentFace.coordsPoint3]}
                                            onClick={() => {}}
                                            options={{
                                                clickable: false,
                                                fillColor: generalBlue,
                                                fillOpacity: 0.9,
                                                strokeColor: '',
                                                strokeOpacity: 0.8,
                                                strokePosition: google.maps.StrokePosition.INSIDE,
                                                strokeWeight: 7
                                            }}
                                        />
                                        {/* <InfoWindow
                                            position={{
                                                lat: (currentFace.coordsPoint1.lat + currentFace.coordsPoint2.lat + currentFace.coordsPoint3.lat) / 3 + 0.00005,
                                                lng: (currentFace.coordsPoint1.lng + currentFace.coordsPoint2.lng + currentFace.coordsPoint3.lng) / 3 - 0.0001
                                            }}
                                        >
                                            <div style={{
                                                border: '3px solid #ffffff',
                                                borderRadius: '5px',
                                                color: 'white',
                                                display: ((zoom && zoom >= 18) || currentFace.face === 'A') ? '' : 'none',
                                                font: '15px Sans-serif',
                                                fontWeight: 'bold',
                                                height: '36px',
                                                padding: '6px',
                                                textAlign: 'center',
                                                width: '136px'
                                            }}>
                                                {zoom && zoom >= 18 ?
                                                    <span> {t.territoryNumber} - {currentFace.block} - {currentFace.face} </span>
                                                    :
                                                    <span> Territorio {t.territoryNumber} </span>
                                                }
                                            </div>
                                        </InfoWindow> */}
                                    </Fragment>
                                )}
                            </div>
                        ))}
                    </GoogleMap>
                </>
            }
        </div>
    )
}
