import { Button } from 'react-bootstrap'
import { FC, useState } from 'react'
import { typeTerritoryNumber } from '../../models'
import { useGetIsDarkMode } from '../commons/custom-hooks'

type propsType = {
    mapId: string
    territoryNumber: typeTerritoryNumber
}

export const StaticMap: FC<propsType> = ({ mapId, territoryNumber }) => {
    const isDarkMode = useGetIsDarkMode();
    const [showMap, setShowMap] = useState(false)

    return (
        <>
            <Button variant={'dark'}
                onClick={() => setShowMap(x => !x)}
                style={{ display: 'block', margin: '22px auto' }}
            >
                {showMap ? "Ocultar Mapa" : "Ver Mapa"}
            </Button>
            <div className={showMap ? 'd-block' : 'd-none'}
                style={{
                    border: isDarkMode ? '1px solid white' : '1px solid black',
                    borderRadius: '8px',
                    height: 'auto',
                    margin: '30px auto 50px auto'
                }}
            >
                <iframe src={`https://drive.google.com/file/d/${mapId}/preview`}
                    style={{
                        border: '1px solid black',
                        borderRadius: '8px',
                        height: '400px',
                        margin: '30px auto 50px',
                        display: 'block',
                        pointerEvents: 'none'
                    }}
                    title='Mapa'
                ></iframe>
            </div>

            {/* <img src={`https://drive.google.com/file/d/${mapId}/view?usp=sharing`}
                alt={`Mapa del territorio ${territoryNumber}`}
                className={showMap ? 'd-block' : 'd-none'}
                style={{
                    border: isDarkMode ? '1px solid white' : '1px solid black',
                    borderRadius: '8px',
                    height: 'auto',
                    margin: '30px auto 50px auto',
                    padding: isMobile ? '10px' : '20px',
                    width: isMobile ? '99%' : '40%'
                }}
            /> */}
        </>
    )
}
