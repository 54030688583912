import { addBoardItemService } from '../../services';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../store';
import { typeBoardItem } from '../../models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from '../commons/custom-hooks';

type propsType = {
    items: typeBoardItem[];
    setCounterReload: Dispatch<SetStateAction<number>>;
}

// 'Plan de Emergencias'
// 'Anuncios y Cartas'
// 'Fechas Especiales'
// 'Reuniones'
// 'Predicación'
// 'Sonido y Acomodadores'
// 'Limpieza'
// 'Grupos'

export const BoardManagerAddItem: FC<propsType> = ({ items, setCounterReload }) => {
    const [title, setTitle] = useState('');
    const [showForm, setShowForm] = useState(false);
    const isDarkMode = useGetIsDarkMode();
    const dispatch = useDispatch()

    const isValid: boolean = useMemo(() =>
        !!title && title.length > 5 && !items.map(i => i.title.toLowerCase()).includes(title.toLowerCase())
    , [items, title]);

    const addBoardItem = async () => {
        if (!isValid) return;
        dispatch(showLoadingModalReducer());
        const success = await addBoardItemService(title);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
            setTitle('');
            setShowForm(false);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    return (
        <div>
            {showForm ?
                <div className={`card ${isDarkMode ? 'bg-dark text-white' : ''}`}
                    style={{ margin: '60px auto', maxWidth: '450px', padding: '25px' }}
                >
                    <div className={'card-title text-center mb-4'}> CREAR ÍTEM DE TABLERO </div>

                    <div className={'mb-3'}>
                        <input type={'text'}
                            autoComplete={'off'}
                            className={'form-control'}
                            placeholder={'Título'}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            onKeyDown={e => e.key === 'Enter' ? addBoardItem() : null}
                            autoFocus
                        />
                    </div>

                    <button className={'btn btn-general-blue my-2'}
                        onClick={addBoardItem}
                        disabled={!isValid}
                    >
                        Aceptar
                    </button>

                    <button className={'btn btn-general-red'}
                        onClick={() => {
                            setTitle('');
                            setShowForm(false);
                        }}
                    >
                        Cancelar
                    </button>
                </div>
                :
                <div className={'text-center mt-5'}>
                    <button className={'btn btn-general-blue'}
                        style={{ width: '220px' }}
                        onClick={() => setShowForm(true)}
                    >
                        Agregar Ítem de Tablero
                    </button>
                </div>
            }
        </div>
    )
}
