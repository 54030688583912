import { BsCalendar, BsPen, BsPerson, BsShare, BsTrash } from 'react-icons/bs';
import { DOMAIN, pointer } from '../../../../app-config';
import { getHeaders } from '../../../../services';
import { Modal, Button, Card } from 'react-bootstrap';
import { typeHermano } from '../../../../models/carritos';
import { typeSolicitudRegistro } from '../../../../models/carritos';
import { useLocation } from 'react-router-dom';
import HermanosCarritosFormAgregar from './HermanosCarritosFormAgregar';
import React from 'react';
import SkeletonLoader from '../../../commons/SkeletonLoader';
import WhatsappShareButton from 'react-share/lib/WhatsappShareButton';

export default function HermanosCarritosPage() {
  const [hermanos, setHermanos] = React.useState<typeHermano[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [selectedHermano, setSelectedHermano] = React.useState<typeHermano | null>(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const [showFormAdd, setShowFormAdd] = React.useState(false);
  const [hermanoToEdit, setHermanoToEdit] = React.useState<typeHermano | null>(null);
  const location = useLocation();
  const solicitud = location.state?.solicitud as typeSolicitudRegistro;

  const [isLoading, setIsLoading] = React.useState(false);

  const base = pointer.carritos;

  const normalizeString = (str: string) =>
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

  const filteredHermanos = hermanos?.filter(
    (hermano) =>
      normalizeString(hermano.name).includes(normalizeString(searchTerm)) ||
      normalizeString(hermano.lastName).includes(normalizeString(searchTerm)) ||
      hermano.phone.includes(searchTerm) ||
      hermano.disponibilidad.some(
        (d) =>
          normalizeString(d.punto).includes(normalizeString(searchTerm)) ||
          d.horarios.some((h) => normalizeString(h.dia).includes(normalizeString(searchTerm)))
      )
  );

  const handleShowModal = (hermano: typeHermano) => {
    setSelectedHermano(hermano);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedHermano(null);
  };

  const toggleFormAdd = () => {
    setShowFormAdd(!showFormAdd);
    if (showFormAdd) {
      setSelectedHermano(null);
    }
  };
  const handleSave = (hermano: typeHermano) => {
    if (hermanoToEdit) {
      // Actualizar un hermano existente
      const updatedHermanos = hermanos?.map((h) => (h.user.id === hermanoToEdit.user.id ? hermano : h));
      setHermanos(updatedHermanos);
    } else {
      // Agregar un nuevo hermano
      setHermanos([...hermanos, hermano]);
    }
    setShowFormAdd(false);
    setHermanoToEdit(null);
  };

  const handleEdit = (hermano: typeHermano) => {
    setHermanoToEdit(hermano);
    toggleFormAdd();
  };

  const handleDelete = async (hermano: typeHermano, index: number) => {
    try {
      const response = await fetch(`${base}/hermanos`, {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(hermano)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      if (response.ok) {
        setHermanos(hermanos?.filter((_, i) => i !== index));
      }

      const data = await response.json();
      console.log('Hermano eliminado:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const shareInvitation = () => {
    // Implementar la funcionalidad de compartir invitación
    console.log('Compartir invitación');
  };

  React.useEffect(() => {
    getHermanos();

    if(solicitud){
      setShowFormAdd(true);
      let hermano = {
        user: solicitud.user,
        name: solicitud.name,
        lastName: solicitud.lastName,
        phone: '',
        participo: solicitud.participo,
        disponibilidad: solicitud.disponibilidad,
        prioridad: '1',
      }
      setHermanoToEdit(hermano);
      toggleFormAdd();
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const getHermanos = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${base}/hermanos`, {headers: getHeaders()});
      const data = await response.json();
      setHermanos(data.hermanos);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching hermanos:', error);
      setIsLoading(false);
    }
  };

  return (
    // style={{ maxWidth: '650px' }}
    <div className="container mt-4">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="mb-4 mt-2">
          {!showFormAdd ? 'Listado de hermanos' : 'Agregar hermano'} <BsPerson />
        </h3>
        <div className="d-flex gap-3">
          <WhatsappShareButton
            onClick={() => {}}
            url={`${DOMAIN}/acceso?url=/gestor-carritos/autoregistro`}
            windowWidth={660}
            windowHeight={460}>
            <button onClick={() => shareInvitation()} className="btn btn-general-blue mb-4 mt-2">
              Compartir invitación {'  '}
              <BsShare></BsShare>
            </button>
          </WhatsappShareButton>

          <button onClick={() => setShowFormAdd(!showFormAdd)} className="btn btn-general-blue mb-4 mt-2">
            {!showFormAdd ? 'Agregar hermano' : 'Ver listado'}
          </button>
        </div>
      </div>

      {!showFormAdd && (
        <div>
          <input
            type="text"
            className="form-control mb-4"
            placeholder="Buscar por nombre, apellidos o teléfono..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="d-none d-md-block">
            <table className="table table-bordered table-responsive shadow">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Teléfono</th>
                  <th>Participó</th>
                  <th>Prioridad</th>
                  <th>Disponibilidad</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      <div role="status">
                        <SkeletonLoader width="98%" height="180px"></SkeletonLoader>
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  filteredHermanos?.map((hermano, index) => (
                    <tr key={index}>
                      <td>{hermano.name}</td>
                      <td>{hermano.lastName}</td>
                      <td>{hermano.phone}</td>
                      <td>{hermano.participo ? 'Sí' : 'No'}</td>
                      <td>{hermano.prioridad === '1' ? 'Alta' : hermano.prioridad === '2' ? 'Media' : 'Baja'}</td>
                      <td>
                        <button className="btn btn-general-blue w-100" onClick={() => handleShowModal(hermano)}>
                          <BsCalendar />
                        </button>
                      </td>
                      <td className="d-flex align-items-center justify-content-center gap-2">
                        <button onClick={() => handleEdit(hermano)} className="btn btn-general-blue">
                          <BsPen />
                        </button>
                        <button className="btn btn-danger" onClick={() => handleDelete(hermano, index)}>
                          <BsTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="d-md-none">
            {isLoading && (
              <div className="col-12 d-flex flex-wrap gap-3 mb-3">
                <SkeletonLoader width="98%" height="80px"></SkeletonLoader>
                <SkeletonLoader width="98%" height="80px"></SkeletonLoader>
                <SkeletonLoader width="98%" height="80px"></SkeletonLoader>
              </div>
            )}
            {filteredHermanos?.map((hermano, index) => (
              <Card key={index} className="mb-3 custom-card" onClick={() => handleShowModal(hermano)}>
                <Card.Body className="d-flex justify-content-between align-items-center">
                  <div>
                    <Card.Title>
                      {hermano.name} {hermano.lastName}
                    </Card.Title>
                  </div>
                  <div className="d-flex gap-2">
                    <Button onClick={() => handleEdit(hermano)} variant="primary">
                      <BsPen />
                    </Button>
                    <Button variant="danger" onClick={() => handleDelete(hermano, index)}>
                      <BsTrash />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      )}

      {showFormAdd && (
        <HermanosCarritosFormAgregar
          hermanoToEdit={hermanoToEdit || undefined}
          solicitud={solicitud}
          onSave={handleSave}
          onCancel={toggleFormAdd}
        />
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Detalles de {selectedHermano?.name} {selectedHermano?.lastName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedHermano && (
            <div>
              {isMobile ? (
                <>
                  <p>
                    <strong>Nombre:</strong> {selectedHermano.name}
                  </p>
                  <p>
                    <strong>Apellido:</strong> {selectedHermano.lastName}
                  </p>
                  <p>
                    <strong>Teléfono:</strong> {selectedHermano.phone}
                  </p>
                  <p>
                    <strong>Participó:</strong> {selectedHermano.participo ? 'Sí' : 'No'}
                  </p>
                  <p>
                    <strong>Prioridad:</strong> {selectedHermano.prioridad}
                  </p>
                </>
              ) : null}
              <p>
                <strong>Disponibilidad:</strong>
              </p>
              {selectedHermano.disponibilidad?.map((disponibilidad, index) => (
                <div key={index}>
                  <h5>{disponibilidad.punto}</h5>
                  <ul>
                    {disponibilidad.horarios?.map((horario, idx) => (
                      <li key={idx}>
                        {horario.dia}: {horario.horarios.join(', ')}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
