import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useGetRecaptchaToken = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const getRecaptchaToken = async (): Promise<string | null> => {
        if (!executeRecaptcha) return null;
        const recaptchaToken = await executeRecaptcha();
        return recaptchaToken;
    }

    return getRecaptchaToken;
}
