import React, { useState, useEffect } from 'react';
import { typeUser } from '../../../../models';
import { getHeaders, getUsersService } from '../../../../services';
import { Loading } from '../../../commons';
import { pointer } from '../../../../app-config';
import {
  typeDisponibilidad,
  typeDisponibilidadForm,
  typeHermano,
  typeHorario,
  typePunto,
  typeSolicitudRegistro
} from '../../../../models/carritos';
import DisponibilidadCarritosForm from '../carritos-common/DisponibilidadCarritosForm';
import Select from 'react-select';

const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const base = pointer.carritos;

type Props = {
  hermanoToEdit?: typeHermano;
  solicitud?: typeSolicitudRegistro;
  onSave: (hermano: typeHermano) => void;
  onCancel: () => void;
};

const HermanosCarritosFormAgregar: React.FC<Props> = ({ hermanoToEdit, solicitud,onSave, onCancel }) => {
  const [puntos, setPuntos] = useState<typePunto[]>([]);
  const [horarios, setHorarios] = useState<typeHorario[]>([]);
  const [users, setUsers] = useState<typeUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<typeUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = async () => {
    setIsLoading(true);
    const users = await getUsersService();
    if (users) {
      setUsers(users);
    }
  };

  const setSolicitudCompleted = async () => {
    if(solicitud) solicitud.status = 'completed';
    try {
      const response = await fetch(`${base}/solicitudes`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(solicitud)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const data = await response.json();
      console.log('Solicitud actualizada:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // services/index.ts

  const getPuntosService = async (): Promise<string[]> => {
    try {
      const response = await fetch(`${base}/puntos`, {headers: getHeaders()});
      if (!response.ok) throw new Error('Error al obtener puntos');
      const data = await response.json();
      setPuntos(data.puntos);
      return data.puntos;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getHorariosService = async (): Promise<string[]> => {
    try {
      const response = await fetch(`${base}/horarios`, {headers: getHeaders()});
      if (!response.ok) throw new Error('Error al obtener horarios');
      const data = await response.json();
      setHorarios(data.horarios);
      return data.horarios;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    fetchUsers();
    const fetchPuntosAndHorarios = async () => {
      await getPuntosService();
      await getHorariosService();
      setIsLoading(false);
      if (hermanoToEdit) {
        setValuesToEdit(hermanoToEdit);
      }
    };
    fetchPuntosAndHorarios();
  }, []);

  const setValuesToEdit = (hermanoEdit: typeHermano) => {
    setSelectedUser(hermanoEdit.user);
    setName(hermanoEdit.name);
    setLastName(hermanoEdit.lastName);
    setPhone(hermanoEdit.phone);
    setParticipo(hermanoEdit.participo);
    setSelectedPrioridad(hermanoEdit.prioridad);
    setDisponibilidad(hermanoEdit.disponibilidad);
  };

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [participo, setParticipo] = useState(false);
  const [disponibilidad, setDisponibilidad] = useState<typeDisponibilidad[]>([]);
  const [selectedPrioridad, setSelectedPrioridad] = useState('1');

  const handleAddDisponibilidad = (newDisponibilidadInput: typeDisponibilidadForm) => {
    const { punto, horario, dia } = newDisponibilidadInput;

    setDisponibilidad((prevDisponibilidad) => {
      const newDisponibilidad = [...prevDisponibilidad];
      const puntoIndex = newDisponibilidad.findIndex((d) => d.punto === punto);

      if (puntoIndex !== -1) {
        const diaIndex = newDisponibilidad[puntoIndex].horarios.findIndex((h) => h.dia === dia);

        if (diaIndex !== -1) {
          if (!newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.includes(horario)) {
            newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.push(horario);
          } else {
            alert('El horario ya existe para este día en este punto.');
          }
        } else {
          newDisponibilidad[puntoIndex].horarios.push({ dia, horarios: [horario] });
        }
      } else {
        newDisponibilidad.push({ punto, horarios: [{ dia, horarios: [horario] }] });
      }

      return newDisponibilidad;
    });
  };

  const handleDeleteDisponibilidad = (puntoIndex: number, diaIndex: number, horarioIndex: number) => {
    const newDisponibilidad = [...disponibilidad];
    newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.splice(horarioIndex, 1);
    if (newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.length === 0) {
      newDisponibilidad[puntoIndex].horarios.splice(diaIndex, 1);
    }
    if (newDisponibilidad[puntoIndex].horarios.length === 0) {
      newDisponibilidad.splice(puntoIndex, 1);
    }
    setDisponibilidad(newDisponibilidad);
  };

  const clearForm = () => {
    setSelectedUser(null);
    setName('');
    setLastName('');
    setPhone('');
    setParticipo(false);
    setDisponibilidad([]);
    setSelectedPrioridad('1');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (hermanoToEdit && hermanoToEdit.id) {
      const updatedHermano: typeHermano = {
        name,
        lastName,
        phone,
        participo,
        disponibilidad,
        prioridad: selectedPrioridad,
        user: selectedUser as typeUser,
        id: hermanoToEdit.id
      };
      handleUpdate(updatedHermano);
    } else if (hermanoToEdit && !hermanoToEdit.id) {
      setSolicitudCompleted();
      handleCreate(e);
    } else {
      handleCreate(e);
    }
  };

  const handleUpdate = async (hermanoUpdate: typeHermano) => {
    try {
      const response = await fetch(`${base}/hermanos`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(hermanoUpdate)
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const data = await response.json();
      console.log('Hermano actualizado:', data);
      onSave(hermanoUpdate);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCreate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newHermano = {
      name,
      lastName,
      phone,
      participo,
      disponibilidad,
      prioridad: selectedPrioridad,
      user: selectedUser as typeUser
    };

    try {
      const response = await fetch(`${base}/hermanos`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newHermano)
      });

      if (!response.ok) {
        throw new Error('Error al agregar el hermano');
      }

      const result = await response.json();
      onSave(newHermano);
      console.log('Hermano agregado exitosamente:', result);
      clearForm(); // Limpiar el formulario después de enviar.
    } catch (error) {
      console.error('Error al enviar los datos:', error);
    }
  };

  return (
    <div className="container p-2">
      {isLoading ? <Loading /> : 
      <form onSubmit={handleSubmit}>
        <div className="mb-3 row ">
          <div className="col-md-6 col-sm-12 m-auto mb-2">
            <label className="form-label">Seleccionar Usuario</label>
            <Select
              options={users.sort((u1, u2) => u1.email.localeCompare(u2.email))}
              getOptionLabel={user => `${user?.email}`}
              getOptionValue={user => user?.id.toString() ?? null}
              onChange={user => setSelectedUser(user ?? null)}
              defaultValue={selectedUser ? selectedUser : null}
              required
              autoFocus
            />
            {/* <select
              name="user"
              className="form-select"
              value={selectedUser ? selectedUser.id : ''}
              onChange={(e) => {
                const user = users.find((user) => user.id.toString() === e.target.value);
                setSelectedUser(user || null);
              }}
              required>
              <option value="" disabled>
                Seleccione un usuario
              </option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.email}
                </option>
              ))}
            </select> */}
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6 col-sm-12">
            <label className="form-label">Nombre</label>
            <input
              placeholder="Ingrese nombre"
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <label className="form-label">Apellido</label>
            <input
              placeholder="Ingrese apellido"
              type="text"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mt-4 row">
          <div className="mb-3 col-md-6 col-sm-12">
            <label className="form-label">Teléfono</label>
            <input
              placeholder="Ingrese numero de celular"
              type="text"
              className="form-control"
              value={phone}
              // value={selectedUser ? selectedUser.phoneNumber ?? 'No registró' : '-'}
              onChange={(e) => setPhone(e.target.value)}
              // disabled
            />
          </div>

          <div className="mb-3 col-md-6 col-sm-12">
            <label className="form-label">Prioridad</label>
            <select
              name="prioridad"
              className="form-select"
              value={selectedPrioridad}
              onChange={(e) => setSelectedPrioridad(e.target.value)}
              required>
              <option value="1">Alta</option>
              <option value="2">Media</option>
              <option value="3">Baja</option>
            </select>
          </div>
          <div className="mb-3 form-check col-md-6 col-sm-12 d-flex align-items-center justify-content-center w-100 gap-4 mt-4">
            <input
              style={{ width: '25px', height: '25px' }}
              type="checkbox"
              className="form-check-input"
              checked={participo}
              onChange={(e) => setParticipo(e.target.checked)}
            />
            <label className="form-check-label fw-bold fs-5">¿Ya participó alguna vez?</label>
          </div>
        </div>
        <hr />
        <div className="row">
          <DisponibilidadCarritosForm
            puntos={puntos}
            dias={dias}
            horarios={horarios}
            disponibilidad={disponibilidad}
            onAddDisponibilidad={handleAddDisponibilidad}
            onRemoveDisponibilidad={handleDeleteDisponibilidad}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            disabled={!name || !lastName || !phone || !disponibilidad}
            type="submit"
            style={{ width: '50%' }}
            className="btn btn-general-blue mt-3 mb-3 btn-block btn-lg">
            Guardar
          </button>
        </div>
      </form>
}
    </div>
  );
};

export default HermanosCarritosFormAgregar;
