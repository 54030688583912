import { BoardManagerAddItem } from './BoardManagerAddItem';
import { BoardManagerFilesList } from './BoardManagerFilesList';
import { BoardManagerUploadFile } from './BoardManagerUploadFile';
import { deleteBoardItemService, reorderBoardItemsService } from '../../services';
import { Dispatch, FC, SetStateAction } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../store';
import { typeBoardItem } from '../../models';
import { useDispatch } from 'react-redux';
import { useGetIsMobile } from '../commons/custom-hooks';

type propsType = {
    items: typeBoardItem[];
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardManager: FC<propsType> = ({ items, setCounterReload }) => {
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();

    const reorder = async (item: typeBoardItem, up: boolean) => {
        dispatch(showLoadingModalReducer());
        const filteredItems = items.filter(i => i.id !== 1);
        const reorderedItems = filteredItems.map(i => ({
            ...i,
            order:
                up ?
                i.id === item.id ? i.order - 1 : i.order === item.order - 1 ? i.order + 1 : i.order
                :
                i.id === item.id ? i.order + 1 : i.order === item.order + 1 ? i.order - 1 : i.order
        }));
        const success = await reorderBoardItemsService(reorderedItems);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    const deleteItem = (item: typeBoardItem) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar Eliminar',
            message: `Se van a eliminar el Ítem '${item.title}' y todos sus archivos. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteBoardItemService(item);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setCounterReload(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Hubo un error',
                        message: 'Algo falló y no se pudo eliminar',
                        mode: 'alert',
                        animation: 2
                    }));
                }
            }
        }));
    }

    return (<>
        {items.filter(i => i.id !== 1).sort((i1, i2) => i1.order - i2.order).map(item =>
            <div key={item.title} className={'card m-3'}>
                <div className={'card-header d-flex justify-content-between align-items-center'}>
                    <h3>{item.title}</h3>
                    <div className={'text-nowrap'}>
                        {items.length > 2 && item.order !== 1 &&
                            <button className={'btn btn-link p-0 mb-2'} onClick={() => reorder(item, true)}>
                                <FaArrowUp size={isMobile ? 15 : 25} />
                            </button>
                        }
                        {items.length > 2 && item.order !== Math.max(...items.filter(i => i.id !== 1).map(i => i.order)) &&
                            <button className={'btn btn-link p-0 mb-2 ms-2'} onClick={() => reorder(item, false)}>
                                <FaArrowDown size={isMobile ? 15 : 25} />
                            </button>
                        }
                    </div>
                </div>
                <div className={'card-body'}>
                    <BoardManagerFilesList
                        item={item}
                        setCounterReload={setCounterReload}
                    />
                    <BoardManagerUploadFile
                        currentItem={item}
                        setCounterReload={setCounterReload}
                    />
                    {/* <div className={'mt-4 mb-2'}>
                        <div className={'form-check'}>
                            <input type={'checkbox'} id={'cartsCheckbox'}
                                className={'form-check-input '}
                                checked={true}
                                onChange={() => {}}
                            />
                            <label className={'form-check-label'} htmlFor={'cartsCheckbox'}>
                                Mostrar Calendario de Carritos
                            </label>
                        </div>
                    </div> */}
                    <div className={'mt-4 mb-2 text-center'}>
                        <button className={'btn btn-general-red'} onClick={() => deleteItem(item)}>
                            Eliminar Ítem
                        </button>
                    </div>
                </div>
            </div>
        )}
        <BoardManagerAddItem
            items={items}
            setCounterReload={setCounterReload}
        />
    </>)
}
